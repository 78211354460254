import { lazy } from "react";

const promoMangamentRoutes = [
  {
    path: "/promo-management",
    component: lazy(() => import("../views/pages/PromoManagement/index")),
    exact: true,
  },
  {
    path: "/promo-management/voucher/:id",
    component: lazy(() => import("../views/pages/VoucherDetail")),
    exact: true,
  },
  {
    path: "/promo-management/add/voucher",
    component: lazy(() => import("../views/pages/PromoManagement/AddVoucherPromoManagement")),
    exact: true,
  },
  {
    path: "/promo-management/voucher/edit/:id",
    component: lazy(() => import("../views/pages/PromoManagement/DetailVoucher/EditVoucher")),
    exact: true,
  },
  {
    path: "/promo-management/discount/add",
    component: lazy(() =>
      import("../views/pages/PromoManagement/AddDiscountPromoManagement/index")
    ),
    exact: true,
  },
  {
    path: "/promo-management/discount/:id",
    component: lazy(() => import("../views/pages/PromoManagement/DetailDiscount/index")),
    exact: true,
  },
  {
    path: "/promo-management/discount/edit/:id",
    component: lazy(() => import("../views/pages/PromoManagement/DetailDiscount/EditDiscount")),
    exact: true,
  },
];

export default promoMangamentRoutes;
