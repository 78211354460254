import { lazy } from "react";

const authRoutes = [
  {
    path: "/",
    component: lazy(() => import("../views/pages/Login")),
    exact: true,
    type: "login",
  },
  {
    path:"/lupa-password",
    component:lazy(() => import("../views/pages/ForgotPassword")),
    exact:true,
    type:"forgot-password"
  },
  {
    path:"/reset",
    component:lazy(() => import("../views/pages/ForgotPassword/SetPassword")),
    exact:true,
    type:"reset-password"
  }
];

export default authRoutes;
