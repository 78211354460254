import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getSetting = createAsyncThunk("getSetting", async (_, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
        const response = await axios({
            method: "get",
            url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/setting`, // Sesuaikan base URL
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error(error.response, "error response");
        return rejectWithValue(error);
    }
});
export const updateSetting = createAsyncThunk("updateSetting", async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
        const response = await axios({
            method: "put",
            url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/setting`, // URL API
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data, // Body request
        });
        return response.data;
    } catch (error) {
        console.error(error.response, "error response");
        return rejectWithValue(error);
    }
});
