import { createSlice } from "@reduxjs/toolkit";
import { getListVariant } from "./variant.api";

const variantSlice = createSlice({
  name: "variant",
  initialState: {
    loading: false,
    listVariant: [],
  },
  extraReducers: {
    [getListVariant.pending]: (state, action) => {
      state.loading = true;
    },
    [getListVariant.fulfilled]: (state, { payload }) => {
      state.loading = false;

      state.listVariant = payload.data;
    },
    [getListVariant.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default variantSlice.reducer;
