import { lazy } from "react";

const adminManagementRoutes = [
  {
    path: "/admin-management",
    component: lazy(() => import("../views/pages/AdminManagement/index")),
    exact: true,
  },
  {
    path: "/add-admin-management",
    component: lazy(() => import("../views/pages/AdminManagement/AddAdminManagement/index")),
    exact: true,
  },
  {
    path: "/konfir-admin-management",
    component: lazy(() =>
      import("../views/pages/AdminManagement/AddAdminManagement/KonfirmasiAdmin")
    ),
    exact: true,
  },
  {
    path: "/dealer-admin-management",
    component: lazy(() => import("../views/pages/AdminManagement/AddAdminManagement/DealerAdmin")),
    exact: true,
  },
  {
    path: "/detail-dealer-admin-management",
    component: lazy(() => import("../views/pages/AdminManagement/AddAdminManagement/DetailDealer")),
    exact: true,
  },
  {
    path: "/detail-admin-management/:id",
    component: lazy(() => import("../views/pages/AdminManagement/DetailAdmin")),
    exact: true,
  },
  {
    path: "/edit-detail-admin-management",
    component: lazy(() => import("../views/pages/AdminManagement/DetailAdmin/EditDetailAdmin")),
    exact: true,
  },
  {
    path: "/edit-admin-management/:id",
    component: lazy(() => import("../views/pages/AdminManagement/DetailAdmin/EditDetailAdmin")),
    exact: true,
  },
];

export default adminManagementRoutes;
