import { createSlice } from "@reduxjs/toolkit";
import { getListDistrict } from "./district.api";

const districtSlice = createSlice({
    name: 'district',
    initialState: {
        loading: false,
        listDistrict: [],
        districtOptions: []
    },
    extraReducers: {
        [getListDistrict.pending]: (state, action) => {
            state.loading = true
        },
        [getListDistrict.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.listDistrict = payload.data
            const mappingDistrict = payload.data.reduce((filtered, option) => {
                const opt = { label: option.name, value: option.id }
                filtered.push(opt)
                return filtered
            }, [])
            state.districtOptions = mappingDistrict
        },
        [getListDistrict.rejected]: (state, action) => {
            state.loading = false
        },
    }

})

export default districtSlice.reducer