import { createSlice } from '@reduxjs/toolkit';
import { getListAdmin, getDetailAdmin, bodyCreate, dataDealer, putAdmin } from './adminManagement.api';

const adminManagementSlice = createSlice({
  name: "admin",
  initialState: {
    loading: false,
    listAdmin: [],
    totalPage:0,
    totalData:0,
    dataBodyCreate: {},
    dataDealer: {},
    detailAdmin: {}
  },
  extraReducers: {
    [getListAdmin.pending]: (state, action) => {
      state.loading = true;
    },
    [getListAdmin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listAdmin = payload.data
      state.totalPage = payload.data.total_page
      state.totalItem = payload.data.total_item
    },
    [getListAdmin.rejected]: (state, action) => {
      state.loading = false
    },
    [getDetailAdmin.pending]: (state, action) => {
      state.loading = true;
    },
    [getDetailAdmin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.detailAdmin = payload.data
      
    },
    [getDetailAdmin.rejected]: (state, action) => {
      state.loading = false
    },
    [bodyCreate.fulfilled]: (state, { payload }) => {
      state.dataBodyCreate = payload
    },
    [dataDealer.fulfilled]: (state, { payload }) => {
      state.dataDealer = payload
    },
    [putAdmin.pending]: (state, action) => {
      state.loading = true;
    },
    [putAdmin.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [putAdmin.rejected]: (state, action) => {
      state.loading = false
    },
  }
});

export default adminManagementSlice.reducer;