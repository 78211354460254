import { lazy } from "react";

const settingRoutes = [
    {
        path: "/setting",
        component: lazy(() => import("../views/pages/SettingPage")), // Placeholder component
        exact: true,
    },
];

export default settingRoutes;