import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

export function useNotificationList(params) {
  return useQuery({
    queryKey: ["notification-list"],
    queryFn: async () => {
      const token = localStorage.getItem("accessToken");
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/notifications`, {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    },
  });
}

export function useNotificationUpdate({ onSuccess, onError } = {}) {
  return useMutation(
    ["notification-update"],
    async ({ id, value }) => {
      const token = localStorage.getItem("accessToken");
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/notifications/read/${id}`,
        value,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    },
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );
}
export function useNotificationsReadAll({ onSuccess, onError } = {}) {
  return useMutation(
    ["notification-read-all"],
    async (value) => {
      const token = localStorage.getItem("accessToken");
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/notifications/read-all`,
        value,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    },
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );
}
export function useNotificationBlastCreate({ onSuccess, onError } = {}) {
  return useMutation(
    ["customer-said-create"],
    async (input) => {
      const token = localStorage.getItem("accessToken");
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/notifications/blast`,
        input,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      return data;
    },
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );
}
