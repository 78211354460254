import { createSlice } from "@reduxjs/toolkit";
import { getcustomer, getDetailCustomer } from "./customer.api";

const customerSlice = createSlice({
  name: "customer",
  initialState: {
    loading: false,
    listCustomer: [],
    detailCustomer: {},
  },
  extraReducers: {
    [getcustomer.pending]: (state, action) => {
      state.loading = true;
    },
    [getcustomer.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listCustomer = payload.data;
    },
    [getcustomer.rejected]: (state, action) => {
      state.loading = false;
    },
    [getDetailCustomer.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.detailCustomer = payload.data;
    },
    [getDetailCustomer.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default customerSlice.reducer;
