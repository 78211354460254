import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getListCar = createAsyncThunk(
  "getListCar/get",
  async (params, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");

    console.log("token", token);

    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/car`,
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const deleteCar = createAsyncThunk("deleteCar/delete", async (data, { rejectWithValue }) => {
  const token = localStorage.getItem("accessToken");
  const { id } = data;
  try {
    const response = await axios({
      method: "delete",
      url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/car/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error.response, "error response");
    return rejectWithValue(error);
  }
});

export const createCar = createAsyncThunk("createCar/create", async (data, { rejectWithValue }) => {
  const token = localStorage.getItem("accessToken");
  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/car`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
    return response.data;
  } catch (error) {
    console.error(error.response, "error response");
    return rejectWithValue(error);
  }
});

export const updateCar = createAsyncThunk("updateCar/update", async (data, { rejectWithValue }) => {
  const token = localStorage.getItem("accessToken");
  const { id, payload } = data;

  // console.log("dataEdit", dataEdit.photo);
  try {
    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/car/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error(error.response, "error response");
    return rejectWithValue(error);
  }
});

export const getDetailCar = createAsyncThunk(
  "getDetailCar/get",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    const id = data;

    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/dashboard/car/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
