import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getHotDeals = createAsyncThunk("hotdeals/get", async (data, { rejectWithValue }) => {
  const token = localStorage.getItem("accessToken");
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/hotdeals/get`,
      params:data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error.response, "error response");
    return rejectWithValue(error);
  }
});

export const deleteHotDeals = createAsyncThunk(
  "hotdeals/delete/",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    const { ID } = data;
    try {
      const response = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_URL}/hotdeals/delete/${ID}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const updateHotDeals = createAsyncThunk(
  "hotdeals/update/",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    // const dispatch = useDispatch();
    const { hotDealsEdit } = data;
    const { dataEdit } = data;
    const { photo } = data;

    try {
      const response = await axios({
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/hotdeals/update/${hotDealsEdit.ID}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          title: dataEdit.hotDeals_title,
          subtitle: dataEdit.hotDeals_subTitle,
          description: dataEdit.hotDeals_description,
          status: dataEdit.hotDeals_status,
          product: dataEdit.hotDeals_produk,
          promo: dataEdit.hotDeals_promo,
          linkphoto: photo == [] || undefined || "" ? "" : photo,
          linkvideo: dataEdit.hotDeals_linkvideo,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);
