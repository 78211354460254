import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getRatingReview = createAsyncThunk("getListReview/product-review", async (params, { rejectWithValue }) => {
  const token = localStorage.getItem("accessToken");
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/dashboard/product-review`,
      params:params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error.response, "error response");
    return rejectWithValue(error);
  }
});

export const getDetailReview = createAsyncThunk("getDetailReview/product-review", async (id, { rejectWithValue }) => {
  const token = localStorage.getItem("accessToken");
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/dashboard/product-review/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const editReview = createAsyncThunk("editReview/put", async (data, { rejectWithValue }) => {
  const token = localStorage.getItem("accessToken");
  try {
    const response = await axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/dashboard/product-review/update-status/${data.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data
    });
    
    return response.data;
  } catch (error) {
    
    return rejectWithValue(error);
  }
});