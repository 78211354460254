import fetchUtil from "../utils/fetchUtil";

const voucherServices = {
  getList: function (limit, currentPage, startDate, endDate, sort, searchKeyword) {
    return fetchUtil.get(`${process.env.REACT_APP_API_URL}/voucher`, {
      params: {
        show: limit,
        page: currentPage,
        start: startDate,
        end: endDate,
        order: sort,
        name: searchKeyword,
      },
    });
  },
  getDetailByID: function (id) {
    return fetchUtil.get(`${process.env.REACT_APP_API_URL}/voucher/${id}`);
  },
  deleteByID: function (id) {
    return fetchUtil.delete(`${process.env.REACT_APP_API_URL}/voucher/${id}`);
  },
  create: function (payload) {
    return fetchUtil.post(`${process.env.REACT_APP_API_URL}/voucher`, payload);
  },
  uploadImage: function (id, data) {
    return fetchUtil.put(`${process.env.REACT_APP_API_URL}/voucher/image/${id}`, data);
  },
  downloadImage: function (imageId) {
    return fetchUtil.get(`${process.env.REACT_APP_API_URL}/voucher/image/${imageId}`, {
      responseType: "blob",
    });
  },
};

export default voucherServices;
