import { createSlice } from "@reduxjs/toolkit";
import { getListBrand, getDetailBrand } from "./brand.api";

const brandSlice = createSlice({
  name: "brand",
  initialState: {
    loading: false,
    listBrand: [],
    carBrandOptions: [],
    productBrandOptions: [],
    detailBrand: {},
    totalItemBrand:0,
    totalPageBrand:0
    
  },
  extraReducers: {
    [getListBrand.pending]: (state, action) => {
      state.loading = true;
    },
    [getListBrand.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listBrand = payload.data.brands;
      state.totalItemBrand = payload.data.total_item;
      state.totalPageBrand = payload.data.total_page
      const mappingCarBrand = payload.data.brands.reduce((filtered, option) => {
        if (option.type === "car") {
          const opt = { label: option.name, value: option.id };
          filtered.push(opt);
        }
        return filtered;
      }, []);
      const mappingProductBrand = payload.data.brands.reduce((filtered, option) => {
        if (option.type === "product") {
          const opt = { label: option.name, value: option.id };
          filtered.push(opt);
        }
        return filtered;
      }, []);
      state.carBrandOptions = mappingCarBrand;
      state.productBrandOptions = mappingProductBrand;
    },
    [getListBrand.rejected]: (state, action) => {
      state.loading = false;
    },
    [getDetailBrand.fulfilled]: (state, { payload }) => {
        state.loading = false
        state.detailBrand = payload.data
    },
    [getDetailBrand.rejected]: (state, action) => {
        state.loading = false
    },
  },
});

export default brandSlice.reducer;
