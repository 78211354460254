import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import App from "./App";
import store from "./store";
import "./styles/global.scss";

import "./assets/scss/main.scss";
import "react-datepicker/dist/react-datepicker.css";
import "react-loading-skeleton/dist/skeleton.css";
import "bootstrap/dist/css/bootstrap.min.css";
import storeToolkit from "./store/storeToolkit";
import axios from "axios";
import Swal from "sweetalert2";
import JwtDecode from "jwt-decode";
import isTokenExpiredUtils from "./utils/isTokenExpiredUtils";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // whatever you want to do with the error
    // console.log(error.response.status, 'global')

    if (error.response.data.response_code === "401") {
      const refreshToken = window.localStorage.getItem("refreshToken");
      let decodedRefreshToken = JwtDecode(refreshToken);
      if (isTokenExpiredUtils(decodedRefreshToken.exp)) {
        Swal.fire({
          title: "Session Expired",
          text: "Your loggin session is expired will redirect to login page",
          timer: 2000,
        }).then(() => {
          window.localStorage.clear();
          window.location.href = "/";
        });
      } else {
        window.location.reload();
      }
    }
    throw error;
  }
);

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <Provider store={storeToolkit}>
      <React.StrictMode>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </BrowserRouter>
      </React.StrictMode>
    </Provider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
