import axios from "axios";
// import fetchUtil from "../utils/fetchUtil";

const authServices = {
  login: async function (payload) {
    return await axios.post(
      `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/user/login`,
      payload
    );
  },
};

export default authServices;
