import axios from "axios";

const shipmentServices = {
  orderRequest: function (payload) {
    return axios.post(
      `${process.env.REACT_APP_SHIPPMENT_SERVICE_URL}/shipper/order-request`,
      payload,
      {
        auth: {
          username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
          password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
        },
      }
    );
  },
  pickupRequest: function (payload) {
    return axios.post(
      `${process.env.REACT_APP_SHIPPMENT_SERVICE_URL}/shipper/pickup-request`,
      payload,
      {
        auth: {
          username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
          password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
        },
      }
    );
  },
};

export default shipmentServices;
