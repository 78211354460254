import React from "react";
import classNames from "classnames";

import "./styles.scss";

const FormGroup = ({ children, withoutMargin, className }) => {
  return (
    <div
      className={classNames(
        "form-group",
        {
          "form-group--without-margin": withoutMargin,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default FormGroup;
