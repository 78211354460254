import { createSlice } from "@reduxjs/toolkit";
import { getListCategory } from "./category.api";

const categorySlice = createSlice({
  name: "category",
  initialState: {
    loading: false,
    listCategory: [],
    categoryOptions: [],
    initialCategoryId: "",
  },
  extraReducers: {
    [getListCategory.pending]: (state, action) => {
      state.loading = true;
    },
    [getListCategory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listCategory = payload.data;
      const mappingCategory = payload.data.reduce((filtered, option) => {
        const opt = { label: option?.name_type, value: option?.id };
        filtered.push(opt);
        return filtered;
      }, []);
      state.categoryOptions = mappingCategory;
      state.initialCategoryId = payload.data[1]?.id;
    },
    [getListCategory.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default categorySlice.reducer;
