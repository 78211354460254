import { createSlice } from "@reduxjs/toolkit";
import { getbanner } from "./banner.api";

const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    loading: false,
    listBanner: [],
    totalDataBanner :0
  },
  extraReducers: {
    [getbanner.pending]: (state, action) => {
      state.loading = true;
    },
    [getbanner.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listBanner = payload.data;
      state.totalDataBanner = payload.total_data
    },
    [getbanner.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default bannerSlice.reducer;
