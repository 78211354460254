import unixToDateTimeUtils from "./unixToDateTimeUtils"

const isTokenExpiredUtils = (tokenExpiredUnixTime) =>{
    let valid
    let expiredTime
    let currentDateTime = new Date()

    expiredTime = unixToDateTimeUtils(tokenExpiredUnixTime)
    if (currentDateTime > expiredTime){
        valid = true
        return valid
    } else if (currentDateTime < expiredTime){
        valid = false
        return valid
    }
}

export default isTokenExpiredUtils