import React from "react";

import "./styles.scss";

const Label = ({ children, htmlFor }) => {
  return (
    <label htmlFor={htmlFor} className="label">
      {children}
    </label>
  );
};

export default Label;
