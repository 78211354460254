import { createSlice } from "@reduxjs/toolkit";
import { getListCity } from "./city.api";

const citySlice = createSlice({
    name: 'city',
    initialState: {
        loading: false,
        listCity: [],
        cityOptions: []
    },
    extraReducers: {
        [getListCity.pending]: (state, action) => {
            state.loading = true
        },
        [getListCity.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.listCity = payload.data
            const mappingCity = payload.data.reduce((filtered, option) => {
                const opt = { label: option.name, value: option.id }
                filtered.push(opt)
                return filtered
            }, [])
            state.cityOptions = mappingCity
        },
        [getListCity.rejected]: (state, action) => {
            state.loading = false
        },
    }

})

export default citySlice.reducer