// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-group {
  margin-bottom: 1.6rem;
}
.form-group:last-child {
  margin-bottom: 0;
}
.form-group--without-margin {
  margin-bottom: 0;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.gap-2 {
  gap: 0.75rem;
}`, "",{"version":3,"sources":["webpack://./src/components/FormGroup/styles.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;AACI;EACI,gBAAA;AACR;AAEI;EACI,gBAAA;AAAR;;AAIA;EACI,aAAA;AADJ;;AAIA;EACI,mBAAA;AADJ;;AAIA;EACI,YAAA;AADJ","sourcesContent":[".form-group {\n    margin-bottom: 1.6rem;\n\n    &:last-child {\n        margin-bottom: 0;\n    }\n\n    &--without-margin {\n        margin-bottom: 0;\n    }\n}\n\n.flex {\n    display: flex;\n}\n\n.items-center {\n    align-items: center;\n}\n\n.gap-2 {\n    gap: 0.75rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
