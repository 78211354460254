import { createSlice } from "@reduxjs/toolkit";
import { forgotPassword } from "./forgotpass.api";


const forgotSlice = createSlice({
    name: 'forgot',
    initialState: {
        loading: false,
    },
    extraReducers: {
        [forgotPassword.pending]: (state, action) => {
            state.loading = true
        },
        [forgotPassword.fulfilled]: (state, { payload }) => {
            state.loading = false
        },
        [forgotPassword.rejected]: (state, action) => {
            state.loading = false
        },
    }

})

export default forgotSlice.reducer