import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getbanner = createAsyncThunk("banner/get", async (data, { rejectWithValue }) => {
  const token = localStorage.getItem("accessToken");
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/banner/get`,
      params:data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error.response, "error response");
    return rejectWithValue(error);
  }
});

export const getbannerID = createAsyncThunk("banner/get/:id", async (data, { rejectWithValue }) => {
  const token = localStorage.getItem("accessToken");
  console.log("token", token);
  const { ID } = data;
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/banner/get/${ID}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
    return response.data;
  } catch (error) {
    console.error(error.response, "error response");
    return rejectWithValue(error);
  }
});

export const deleteBanner = createAsyncThunk(
  "banner/delete/",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    const { ID } = data;
    try {
      const response = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_URL}/banner/delete/${ID}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const updateBanner = createAsyncThunk("banner/update", async (data, { rejectWithValue }) => {
  const token = localStorage.getItem("accessToken");
  // const dispatch = useDispatch();
  const { bannerEdit } = data;
  const { dataEdit } = data;
  const { photo } = data;

  try {
    const response = await axios({
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/banner/update/${bannerEdit.ID}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        title: dataEdit.banner_title,
        subtitle: dataEdit.banner_subTitle,
        description: dataEdit.banner_description,
        status: dataEdit.banner_status,
        linkphoto: photo == [] || undefined || "" ? "" : photo,
        linkvideo: dataEdit.banner_linkvideo,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error.response, "error response");
    return rejectWithValue(error);
  }
});
