import React from "react";

import "./styles.scss";

const Preloader = () => {
  return (
    <div className="lds__overlay">
      <div className="lds__ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Preloader;
