import React, { useState } from "react";
import ImageUploading from "react-images-uploading";
import Wheel from "../../assets/images/bengkel.png";

function AddPhoto({ text }) {
  const [linkphoto, setLinkPhoto] = useState([]);
  return (
    <div className="ms-3">
      <p>{text}</p>
      <ImageUploading
        value={linkphoto !== [] ? linkphoto : Wheel}
        onChange={(val) => setLinkPhoto(val)}
        dataURLKey="data_url"
      >
        {({ onImageUpload, isDragging, dragProps }) => (
          <div
            className="upload__image-wrapper border-1 rounded d-flex align-items-center justify-content-center"
            style={{ minHeight: 100, maxWidth: 200 }}
          >
            <button
              type="button"
              style={isDragging ? { color: "red" } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              {linkphoto && "Click or Drop here"}

              {linkphoto?.map((image, index) => (
                <img key={index} src={image["data_url"]} alt="" width="100" />
              ))}
            </button>
          </div>
        )}
      </ImageUploading>
    </div>
  );
}

export default AddPhoto;
