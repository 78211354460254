import { lazy } from "react";

const customerSaidManagementRoutes = [
  {
    path: "/CustomerSaid-management",
    component: lazy(() => import("../views/pages/CustomerSaidManagement/index")),
    exact: true,
  },
  {
    path: "/CustomerSaid-management/add",
    component: lazy(() => import("../views/pages/CustomerSaidManagement/AddCustomerSaid/index")),
    exact: true,
  },
  {
    path: "/CustomerSaid-management/detail/:id",
    component: lazy(() => import("../views/pages/CustomerSaidManagement/DetailCustomerSaid/index")),
    exact: true,
  },
  {
    path: "/CustomerSaid-management/edit/:id",
    component: lazy(() => import("../views/pages/CustomerSaidManagement/EditCustomerSaid/index")),
    exact: true,
  },
];

export default customerSaidManagementRoutes;
