// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb {
  display: flex;
  align-items: center;
}
.breadcrumb__item {
  display: flex;
  align-items: center;
}
.breadcrumb__item h1 {
  padding: 0 0.5rem;
  color: #454545;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
.breadcrumb__item svg {
  color: #454545;
}
.breadcrumb__item:nth-child(2) h1 {
  color: #e9522a;
}
.breadcrumb__item:last-child svg {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Breadcrumb/styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;AACR;AACQ;EACI,iBAAA;EACA,cAAA;EACA,iBAAA;EACA,iBAAA;EACA,kCAAA;AACZ;AAEQ;EACI,cAAA;AAAZ;AAIY;EACI,cAAA;AAFhB;AAOY;EACI,aAAA;AALhB","sourcesContent":[".breadcrumb {\n    display: flex;\n    align-items: center;\n\n    &__item {\n        display: flex;\n        align-items: center;\n\n        h1 {\n            padding: 0 0.5rem;\n            color: #454545;\n            font-size: 1.5rem;\n            font-weight: bold;\n            font-family: \"Poppins\", sans-serif;\n        }\n\n        svg {\n            color: #454545;\n        }\n\n        &:nth-child(2) {\n            h1 {\n                color: #e9522a;\n            }\n        }\n\n        &:last-child {\n            svg {\n                display: none;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
