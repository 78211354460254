import React from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

const Breadcrumb = ({ items }) => {
  return (
    <div className="breadcrumb">

  {/* {items.map((item, i) => {
        return ( */}
          <Link className="breadcrumb__item" to={items[0].to}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 6L4 12M4 12L10 18M4 12H19"
          stroke="#E9522A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      </Link>
        {/* );
      })} */}

      {items.map((item, i) => {
        return (
          <Link className="breadcrumb__item" key={i} to={item.to}>
            <h1>{item.title}</h1>
            {item.noIcon ? "" :  <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 6L15 12L9 18"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>}
           
          </Link>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
