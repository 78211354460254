import { lazy } from "react";

const voucherManagementRoutes = [
  {
    path: "/voucher-management",
    component: lazy(() => import("../views/pages/VoucherManagement/VoucherList")),
    exact: true,
  },
  {
    path: "/voucher-management/add",
    component: lazy(() => import("../views/pages/CreateVoucher")),
    exact: true,
  },
  {
    path: "/voucher-management/:id",
    component: lazy(() => import("../views/pages/VoucherDetail")),
    exact: true,
  },
];

export default voucherManagementRoutes;
