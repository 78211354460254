import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getListWarehouse = createAsyncThunk('getListWarehouse/get', async (data, { rejectWithValue }) => {
    let searchKeyword = data.searchKeyword 
    if(data.searchKeyword ===""){
            searchKeyword = null
    }
    const token = localStorage.getItem('accessToken')
    try {
        const response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/warehouse`,
            params: {
                order: data.sort,
                limit: data.limit,
                offset: (data.currentPage - 1) * data.limit,
                name: searchKeyword,
                status : data.status
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})

export const getDetailWarehouse = createAsyncThunk('getDetailWarehouse/get', async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('accessToken')
    try {
        const response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/warehouse/${data}`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})

export const deleteWarehouse = createAsyncThunk('deleteWarehouse/delete', async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('accessToken')
    const { id } = data
    try {
        const response = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/warehouse/${id}`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})

export const createWarehouse = createAsyncThunk('createWarehouse/create', async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('accessToken')
    try {
        const response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/warehouse`,
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data:data
        })
        return response.data
    } catch (error) {
        console.error(error.response, 'error response')
        return rejectWithValue(error)
    }
})

export const updateWarehouse = createAsyncThunk('updateWarehouse/update', async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('accessToken')
    try {
        const response = await axios({
            method: 'put',
            url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/warehouse/${data.id}`,
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data:data
        })
        return response.data
    } catch (error) {
        console.error(error.response, 'error response')
        return rejectWithValue(error)
    }
})