import { createSlice } from "@reduxjs/toolkit";
import { getListTransaction, getDetailTransaction, processTransaction } from "./transaction.api";

const transactionSlice = createSlice({
    name: 'transaction',
    initialState: {
        loading: false,
        listTransaction: [],
        detailTransaction: {},
        totalPage:0,
        totalItem:0,
    },
    extraReducers: {
        // List Transaction
        [getListTransaction.pending]: (state, action) => {
            state.loading = true
        },
        [getListTransaction.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.listTransaction = payload.data.transactions
            state.totalItem = payload.data.total_item
            state.totalPage = payload.data.total_page
        },
        [getListTransaction.rejected]: (state, action) => {
            state.loading = false
        },

        // Detail Transaction
        [getDetailTransaction.pending]: (state, action) => {
            state.loading = true
        },
        [getDetailTransaction.fulfilled]: (state, { payload }) => {
            state.loading = false
            console.info(payload.data.order, "detailTransaction")
            state.detailTransaction = payload.data
        },
        [getDetailTransaction.rejected]: (state, action) => {
            state.loading = false
        },

        // Detail Transaction
        [processTransaction.pending]: (state, action) => {
            state.loading = true
        },
        [processTransaction.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.detailTransaction = payload.data
        },
        [processTransaction.rejected]: (state, action) => {
            state.loading = false
        },
    }

})

export default transactionSlice.reducer