import React from "react";
import classNames from "classnames";

import "./styles.scss";

const SearchInput = ({ placeholder, onChange, value, widthVariant, style, onKeyPress }) => {
  return (
    <div className="search-input justify-content-start" style={style}>
      <svg
        className="search-input__icon"
        width="22"
        height="22"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="11" cy="11" r="7" stroke="#757575" strokeWidth="2" />
        <path d="M20 20L17 17" stroke="#757575" strokeWidth="2" strokeLinecap="round" />
      </svg>

      <input
        type="text"
        className={classNames("search-input__input", {
          "search-input__input--full": widthVariant === "full",
        })}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
    </div>
  );
};

export default SearchInput;
