import React from "react";

import { Select as SelectANTD } from "antd";

import "./styles.scss";

const Select = ({
  mode,
  size,
  id,
  value,
  onChange,
  placeholder,
  options,
  className,
  label,
  defaultValue,
}) => {
  return (
    <>
      {mode === "tag" ? (
        <div className="input-wrapper">
          <label htmlFor={id}>{label}</label>
          <SelectANTD
            mode="tags"
            className={`select select--${size} ${className}`}
            placeholder={placeholder}
            onChange={onChange}
            defaultValue={defaultValue}
          ></SelectANTD>
        </div>
      ) : (
        <div className="input-wrapper">
          <label htmlFor={id}>{label}</label>
          <select
            value={value}
            id={id}
            className={`select select--${size} ${className}`}
            onChange={onChange}
          >
            {placeholder && (
              <option value="" disabled>
                {placeholder}
              </option>
            )}
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
};

export default Select;
