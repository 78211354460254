import fetchUtil from "../utils/fetchUtil";

const transactionServices = {
  getList: function (limit, currentPage, sort, startDate, endDate, typeOrder) {
    return fetchUtil.get(`${process.env.REACT_APP_ORDER_SERVICE_URL}/orders/list`, {
      params: {
        limit,
        page: currentPage,
        sort,
        dateFrom: startDate,
        dateEnd: endDate,
        typeOrder,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
  },
  getDetailByOrderID: function (orderId) {
    return fetchUtil.get(`${process.env.REACT_APP_ORDER_SERVICE_URL}/orders/detail/${orderId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
  },
};

export default transactionServices;
