import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const postLogin = createAsyncThunk('login/post', async (data, { rejectWithValue }) => {
    try {
        const response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_AUTH_SERVICE_URL}/users/login`,
            auth: {
                username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
                password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`
            },
            data: data
        })
        return response.data
    } catch (error) {
        // console.log(error.response, 'error response')
        return rejectWithValue(error.response.data)
    }
})
