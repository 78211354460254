import { combineReducers } from "redux";

import auth from "./auth/reducer";
import transaction from "./transaction/reducer";
import voucher from "./voucher/reducer";
import statistic from "./statistic/reducer";
import shipment from "./shipment/reducer";

const createRootReducer = () =>
  combineReducers({
    auth,
    transaction,
    voucher,
    statistic,
    shipment,
  });

export default createRootReducer;
