import { lazy } from "react";

const transactionManagementRoutes = [
  {
    path: "/transaction-management",
    component: lazy(() => import("../views/pages/TransactionManagement")),
    exact: true,
    type: "transaction",
  },
  {
    path: "/transaction-management/:id",
    component: lazy(() => import("../views/pages/TransactionDetail")),
    exact: true,
    type: "transaction-detail",
  },
];

export default transactionManagementRoutes;
