import { lazy } from "react";

const productManagementRoutes = [
  {
    path: "/product-management",
    component: lazy(() => import("../views/pages/ProductManagement")),
    exact: true,
  },
  {
    path: "/product-management/detail/:id",
    component: lazy(()=> import("../views/pages/ProductManagement/DetailProduct/index")),
    exact: true,
  },
  {
    path: "/product-management/add",
    component: lazy(() => import("../views/pages/ProductManagement/AddProduct/index")),
    exact: true,
  },
  {
    path: "/product-management/add/wheels",
    component: lazy(() => import("../views/pages/ProductManagement/AddProduct/Wheels/index")),
    exact: true,
  },
  {
    path: "/product-management/add/wheels/:id",
    component: lazy(() => import("../views/pages/ProductManagement/AddProduct/Wheels/index")),
    exact: true,
  },
  {
    path: "/product-management/add/part",
    component: lazy(() => import("../views/pages/ProductManagement/AddProduct/Part/index")),
    exact: true,
  },
  {
    path: "/product-management/add/part/:id",
    component: lazy(() => import("../views/pages/ProductManagement/AddProduct/Part/index")),
    exact: true,
  },
  {
    path: "/product-management/add/sus",
    component: lazy(() => import("../views/pages/ProductManagement/AddProduct/Suspension")),
    exact: true,
  },
  {
    path: "/product-management/add/sus/:id",
    component: lazy(() => import("../views/pages/ProductManagement/AddProduct/Suspension")),
    exact: true,
  },
  {
    path: "/product-management/add/ex",
    component: lazy(() => import("../views/pages/ProductManagement/AddProduct/Exhaust")),
    exact: true,
  },
  {
    path: "/product-management/add/ex/:id",
    component: lazy(() => import("../views/pages/ProductManagement/AddProduct/Exhaust")),
    exact: true,
  },
  {
    path: "/product-management/add/tires",
    component: lazy(() => import("../views/pages/ProductManagement/AddProduct/Tires/index")),
    exact: true,
  },
  {
    path: "/product-management/add/tires/:id",
    component: lazy(() => import("../views/pages/ProductManagement/AddProduct/Tires/index")),
    exact: true,
  },
  {
    path: "/product-management/wheel/:id",
    component: lazy(() => import("../views/pages/ProductManagement/DetailWheel/index")),
    exact: true,
  },
  {
    path: "/product-management/tires/:id",
    component: lazy(() => import("../views/pages/ProductManagement/DetailTires/index")),
    exact: true,
  },
  {
    path: "/product-management/suspension/:id",
    component: lazy(() => import("../views/pages/ProductManagement/DetailSuspension/index")),
    exact: true,
  },
  {
    path: "/product-management/exhaust/:id",
    component: lazy(() => import("../views/pages/ProductManagement/DetailExhaust/index")),
    exact: true,
  },
  {
    path: "/product-management/accesories/:id",
    component: lazy(() => import("../views/pages/ProductManagement/DetailAcc/index")),
    exact: true,
  },
  {
    path: "/product-management/wheel/edit/:id",
    component: lazy(() => import("../views/pages/ProductManagement/DetailWheel/EditWheel")),
    exact: true,
  },
  {
    path: "/product-management/tires/edit/:id",
    component: lazy(() => import("../views/pages/ProductManagement/DetailTires/EditTires")),
    exact: true,
  },
  {
    path: "/product-management/suspension/edit/:id",
    component: lazy(() =>
      import("../views/pages/ProductManagement/DetailSuspension/EditSuspension")
    ),
    exact: true,
  },
  {
    path: "/product-management/exhaust/edit/:id",
    component: lazy(() => import("../views/pages/ProductManagement/DetailExhaust/EditExhaust")),
    exact: true,
  },
  {
    path: "/product-management/accesories/edit/:id",
    component: lazy(() => import("../views/pages/ProductManagement/DetailAcc/EditAcc")),
    exact: true,
  },
];

export default productManagementRoutes;
