import { all, put, takeEvery, call } from "redux-saga/effects";

import actions from "./actions";
import { statisticServices } from "../../../services";

export function* GET_ADMIN_GRAPH({ payload }) {
  const { startDate, endDate, group } = payload;

  yield put({ type: actions.SET_ADMIN_GRAPH, payload: { isLoading: true } });

  try {
    const { data } = yield call(statisticServices.getAdminGraph, startDate, endDate, group);

    yield put({ type: actions.SET_ADMIN_GRAPH, payload: { salesOrders: data.result.sales } });
  } catch ({ message: error }) {
    yield put({ type: actions.SET_ADMIN_GRAPH, payload: { error } });
  } finally {
    yield put({ type: actions.SET_ADMIN_GRAPH, payload: { isLoading: false } });
  }
}

export default function* transactionSaga() {
  yield all([takeEvery(actions.GET_ADMIN_GRAPH, GET_ADMIN_GRAPH)]);
}
