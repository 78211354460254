import AuthRoutes from "./authRoutes";
import DashboardRoutes from "./dashboardRoutes";
import TransactionManagementRoutes from "./transactionManagementRoutes";
import ProductManagementRoutes from "./productManagementRoutes";
import HomePageManagementRoutes from "./homePageManagementRoutes";
import VoucherManagementRoutes from "./voucherManagementRoutes";
import ProductRefundRoutes from "./productRefundRoutes";
import CustomerPageManagementRoutes from "./customerPageManagementRoutes";
import AdminManagementRoutes from "./adminManagementRoutes";
import PromoMangamentRoutes from "./promoMangamentRoutes";
import MembershipManagementRoutes from "./membershipManagementRoutes";
import CustomerSaidManagementRoutes from "./customerSaidManagementRoutes";
import RatingReviewRoutes from "./ratingReviewRoutes";
import ArticleManagement from "./articleManagement";
import NotificationBlastRoutes from "./notificationBlastRoute";
import NotificationCenterRoutes from "./notificationCenterRoutes";

export {
  AuthRoutes,
  DashboardRoutes,
  TransactionManagementRoutes,
  ProductManagementRoutes,
  VoucherManagementRoutes,
  ProductRefundRoutes,
  HomePageManagementRoutes,
  CustomerPageManagementRoutes,
  AdminManagementRoutes,
  PromoMangamentRoutes,
  MembershipManagementRoutes,
  CustomerSaidManagementRoutes,
  RatingReviewRoutes,
  ArticleManagement,
  NotificationBlastRoutes,
  NotificationCenterRoutes
};
