import { all, put, takeEvery, call } from "redux-saga/effects";

import actions from "./actions";
import { shipmentServices } from "../../../services";

export function* ORDER_REQUEST({ payload }) {
  yield put({ type: actions.SET_SHIPMENT, payload: { isLoading: true } });

  try {
    const { data } = yield call(shipmentServices.orderRequest, payload);

    yield put({
      type: actions.SET_SHIPMENT,
      payload: { domesticId: data.data.id, isOrdered: true },
    });
  } catch (error) {
    yield put({ type: actions.SET_SHIPMENT, payload: { error: error.response.data.data.content } });
  } finally {
    yield put({ type: actions.SET_SHIPMENT, payload: { isLoading: false } });
  }
}

export function* PICKUP_REQUEST({ payload }) {
  yield put({ type: actions.SET_SHIPMENT, payload: { isLoading: true, isPickedUp: false } });

  try {
    yield call(shipmentServices.pickupRequest, payload);

    yield put({
      type: actions.SET_SHIPMENT,
      payload: { isPickedUp: true },
    });
  } catch (error) {
    yield put({ type: actions.SET_SHIPMENT, payload: { error: error.response.data.data.content } });
  } finally {
    yield put({ type: actions.SET_SHIPMENT, payload: { isLoading: false } });
  }
}

export default function* shipmentSaga() {
  yield all([
    takeEvery(actions.ORDER_REQUEST, ORDER_REQUEST),
    takeEvery(actions.PICKUP_REQUEST, PICKUP_REQUEST),
  ]);
}
