import { createSlice } from "@reduxjs/toolkit";
import { getHotDeals } from "./hotDeals.api";

const hotDealsSlice = createSlice({
  name: "hotDeals",
  initialState: {
    loading: false,
    listHotDeals: [],
    totalHotDeals: 0,
  },
  extraReducers: {
    [getHotDeals.pending]: (state, action) => {
      state.loading = true;
    },
    [getHotDeals.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listHotDeals = payload.data;
      state.totalHotDeals = payload.total_data;
    },
    [getHotDeals.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default hotDealsSlice.reducer;
