import React from "react";
import classNames from "classnames";

import "./styles.scss";

const Radio = ({ selected, onChange, label, value, className, items }) => {
  return (
    <div className="radio">
      {items ? items.map((item, i) => (
        <div className={classNames("radio", className)} onClick={() => onChange(item.label, item.value)} key={i}>
          <div className={classNames("radio__circle--outer ", { selected: item.label === selected })}>
            <div
              className={classNames("radio__circle--inner", { "selected-circle": item.label === selected })}
            />
          </div>
          <div className="radio__label">{item.label}</div>
        </div>
      )) : (
        <div className={classNames("radio", className)} onClick={() => onChange(value)}>
          <div className={classNames("radio__circle--outer ", { selected: value === selected })}>
            <div
              className={classNames("radio__circle--inner", { "selected-circle": value === selected })}
            />
          </div>
          <div className="radio__label">{label}</div>
        </div>
      )}
    </div>
  );
};

export default Radio;
