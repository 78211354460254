import { lazy } from "react";

const adminManagementRoutes = [
  {
    path: "/article-management",
    component: lazy(() => import("../views/pages/ArticleManagement/index")),
    exact: true,
  },
  {
    path: "/article-management/add",
    component: lazy(() => import("../views/pages/ArticleManagement/AddArticleManagement/index")),
    exact: true,
  },
  {
    path: "/article-management/detail/:id",
    component: lazy(() => import("../views/pages/ArticleManagement/DetailArticle/index")),
    exact: true,
  },
  {
    path: "/article-management/detail/edit/:id",
    component: lazy(() =>
      import("../views/pages/ArticleManagement/DetailArticle/EditDetailArticle")
    ),
    exact: true,
  },
];

export default adminManagementRoutes;
