import { all } from "redux-saga/effects";

import authSaga from "../reducers/auth/sagas";
import transactionSaga from "../reducers/transaction/sagas";
import voucherSaga from "../reducers/voucher/sagas";
import statisticSaga from "../reducers/statistic/sagas";
import shipmentSaga from "../reducers/shipment/sagas";

export default function* rootSaga() {
  yield all([authSaga(), transactionSaga(), voucherSaga(), statisticSaga(), shipmentSaga()]);
}
