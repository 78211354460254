import Skeleton from "react-loading-skeleton";
import React from "react";

const TableRowSkeleton = () => {
  return (
    <tr className="table-skeleton-container">
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
    </tr>
  );
};

export default TableRowSkeleton;
