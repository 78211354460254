import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const uploadFile = createAsyncThunk(
  "uploadFile/create",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const formData = new FormData();
      for (const prop in data) {
        formData.append(prop, data[prop]);
      }
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_FILE_SERVICE_URL}/file/upload`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
      return response.data;
    } catch (error) {
      console.error(error, "error response upload file");
      return rejectWithValue(error);
    }
  }
);

export const deleteFile = createAsyncThunk(
  "deleteFile/delete",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_FILE_SERVICE_URL}/file/upload`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: {
          file_url:data.file_url,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error, "error response upload file");
      return rejectWithValue(error);
    }
  }
);
