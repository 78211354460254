import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getListProduct = createAsyncThunk(
  "getListProduct/get",
  async (data, { rejectWithValue }) => {
    const { id } = data;
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/product/`,
        params: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);
export const getListProductAccessories = createAsyncThunk(
  "getListProductAccessories/get",
  async (params, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/product/`,
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const getListProductWheels = createAsyncThunk(
  "getListProductWheeels/get",
  async (params, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/product/`,
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response", response.data);
      return response.data;
    } catch (error) {
      console.error(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const getListProductTires = createAsyncThunk(
  "getListProductTires/get",
  async (params, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/product/`,
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const getListProductSuspension = createAsyncThunk(
  "getListProductSuspension/get",
  async (params, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/product/`,
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const getListProductExhaust = createAsyncThunk(
  "getListProductExhaust/get",
  async (params, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/product/`,
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const getDetailProduct = createAsyncThunk(
  "getDetailProduct/get",
  async (data, { rejectWithValue }) => {
    const { id } = data;
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/product/${id}`,
        // params: {
        //   order: "created_at,asc",
        //   product_category_id: "a40c73de-012d-4efa-b2ab-8918a7b83a2d",
        // },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const getDetailProductVariant = createAsyncThunk(
  "getDetailProductVariant/get",
  async (data, { rejectWithValue }) => {
    const { id } = data;
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/product/variant/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const createProduct = createAsyncThunk(
  "createProduct/create",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/product/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      return response.data;
    } catch (error) {
      console.error(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "updateProduct/update",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/product/${data.product_id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      return response.data;
    } catch (error) {
      console.error(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const updateVariantProduct = createAsyncThunk(
  "updateVariantProduct/update",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/product/variant/${data.product_id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      return response.data;
    } catch (error) {
      console.error(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const deleteVariantProduct = createAsyncThunk(
  "deleteVariantProduct/delete",
  async (product_id, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/product/variant/${product_id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const updateAddon = createAsyncThunk(
  "updateAddon/update",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/product/variant/addons/${data.addon_id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data.payload,
      });
      return response.data;
    } catch (error) {
      console.error(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const postAddon = createAsyncThunk("postAddon/update", async (data, { rejectWithValue }) => {
  const token = localStorage.getItem("accessToken");
  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/product/variant/${data.product_variant_id}/addons`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data.payload,
    });
    return response.data;
  } catch (error) {
    console.error(error.response, "error response");
    return rejectWithValue(error);
  }
});

export const variantBodyCreate = createAsyncThunk("bodyCreate/get", async (data) => {
  return data;
});

export const editVariantBodyCreate = createAsyncThunk(
  "replaceVariantBodyCreate/get",
  async (data) => {
    return data;
  }
);

export const parrentBodyCreate = createAsyncThunk("parrentBodyCreate/get", async (data) => {
  return data;
});

export const replaceVariantBody = createAsyncThunk("replaceVariantBody/get", async () => {
  return;
});

export const destroyBody = createAsyncThunk("destroyBody/get", async () => {
  return;
});

export const editedIndex = createAsyncThunk("editedIndex/get", async () => {
  return;
});
