// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge {
  width: 140px;
  font-size: 0.75rem;
  font-weight: bold;
  color: #757575;
  background-color: #eaeaea;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.45rem 1.5rem;
  border-radius: 10px;
}
.badge--grey {
  color: #ffffff;
  background-color: #4d4d4d;
}
.badge--yellow {
  color: #ffffff;
  background-color: #b58b00;
}
.badge--red {
  color: #ffffff;
  background-color: #b12a00;
}
.badge--blue {
  color: #ffffff;
  background-color: #1f4d7b;
}
.badge--green {
  color: #ffffff;
  background-color: #0d7513;
}
.badge--contrast-red {
  color: #ffffff;
  background-color: #6b0000;
}`, "",{"version":3,"sources":["webpack://./src/components/Badge/styles.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,cAAA;EACA,yBAAA;EACA,oBAAA;EACA,uBAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACI;EACI,cAAA;EACA,yBAAA;AACR;AAEI;EACI,cAAA;EACA,yBAAA;AAAR;AAGI;EACI,cAAA;EACA,yBAAA;AADR;AAII;EACI,cAAA;EACA,yBAAA;AAFR;AAKI;EACI,cAAA;EACA,yBAAA;AAHR;AAMI;EACI,cAAA;EACA,yBAAA;AAJR","sourcesContent":[".badge {\n    width: 140px;\n    font-size: 0.75rem;\n    font-weight: bold;\n    color: #757575;\n    background-color: #eaeaea;\n    display: inline-flex;\n    justify-content: center;\n    align-items: center;\n    padding: 0.45rem 1.5rem;\n    border-radius: 10px;\n\n    &--grey {\n        color: #ffffff; // Teks putih\n        background-color: #4d4d4d; // Latar belakang jauh lebih gelap (abu gelap)\n    }\n\n    &--yellow {\n        color: #ffffff; // Teks putih\n        background-color: #b58b00; // Warna latar jauh lebih gelap (kuning gelap)\n    }\n\n    &--red {\n        color: #ffffff; // Teks putih\n        background-color: #b12a00; // Warna latar lebih gelap (merah pekat)\n    }\n\n    &--blue {\n        color: #ffffff; // Teks putih\n        background-color: #1f4d7b; // Warna latar lebih gelap (biru navy)\n    }\n\n    &--green {\n        color: #ffffff; // Teks putih\n        background-color: #0d7513; // Warna latar lebih gelap (hijau gelap)\n    }\n\n    &--contrast-red {\n        color: #ffffff; // Tetap putih\n        background-color: #6b0000; // Lebih gelap untuk kontras maksimum\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
