import axios from "axios";

// const apiService = {
//   init(baseURL) {
//     axios.defaults.baseURL = baseURL;
//     axios.defaults.headers.common["Accept"] = "application/json";
//     axios.defaults.headers.common["Content-Type"] = "application/json";
//   },

//   withAccessToken() {
//     axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
//       "access_token"
//     )}`;

//     return this;
//   },

//   withBasicAuth() {
//     axios.defaults.auth = {
//       username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
//       password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
//     };

//     return this;
//   },

//   get(resource, config) {
//     return axios.get(resource, config);
//   },

//   post(resource, data) {
//     return axios.post(resource, data, {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//     });
//   },

//   put(resource, data) {
//     return axios.put(resource, data);
//   },

//   delete(resource) {
//     return axios.delete(resource);
//   },
// };

// const fetchUtil = {
//   init(baseURL, headers, auth) {
//     return axios.create({
//       baseURL,
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         ...headers,
//       },
//       auth,
//     });
//   },
// };

const fetchUtil = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default fetchUtil;
