import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getListCity = createAsyncThunk('getListCity/get', async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('accessToken')
    try {
        const response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/region/cities`,
            params: {
                id:data.id,
                province_id: data.province_id
            },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})