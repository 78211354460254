import { createSlice } from "@reduxjs/toolkit";
import { getListVoucher } from "./voucher.api";

const voucherSlice = createSlice({
  name: "voucher",
  initialState: {
    loading: false,
    listVoucher: [],
  },
  extraReducers: {
    [getListVoucher.pending]: (state, action) => {
      state.loading = true;
    },
    [getListVoucher.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listDealer = payload.data.dealers;
    },
    [getListVoucher.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default voucherSlice.reducer;
