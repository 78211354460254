import actions from "./actions";

const initialState = {
  isLoading: false,
  error: null,
  domesticId: "",
  isOrdered: false,
  isPickedUp: false,
};

export default function assessmentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_SHIPMENT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
