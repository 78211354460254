import { createSlice } from "@reduxjs/toolkit";
import { getListRegency } from "./regency.api";

const regencySlice = createSlice({
    name: 'regency',
    initialState: {
        loading: false,
        listRegency: [],
        regencyOptions: []
    },
    extraReducers: {
        [getListRegency.pending]: (state, action) => {
            state.loading = true
        },
        [getListRegency.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.listRegency = payload.data
            const mappingRegency = payload.data.reduce((filtered, option) => {
                const opt = { label: option.name, value: option.id, lat:option.lat,lng :option.lng, postcode:option.postcode}
                filtered.push(opt)
                return filtered
            }, [])
            state.regencyOptions = mappingRegency
        },
        [getListRegency.rejected]: (state, action) => {
            state.loading = false
        },
    }

})

export default regencySlice.reducer