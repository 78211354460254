import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getcustomer = createAsyncThunk(
  "dashboard/customer",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");

    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/dashboard/customer`,
        params: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const getDetailCustomer = createAsyncThunk(
  "getDetailCustomer/get",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    const id = data;

    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/dashboard/customer/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// export const deleteBanner = createAsyncThunk(
//   "banner/delete/",
//   async (data, { rejectWithValue }) => {
//     const token = localStorage.getItem("accessToken");
//     const { ID } = data;
//     try {
//       const response = await axios({
//         method: "delete",
//         url: `${process.env.REACT_APP_API_URL}/banner/delete/${ID}`,
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       return response.data;
//     } catch (error) {
//       console.log(error.response, "error response");
//       return rejectWithValue(error);
//     }
//   }
// );

export const updateCustomer = createAsyncThunk(
  "customer/update",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    const { id, status } = data;

    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/dashboard/customer/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          status: status,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);
