import { lazy } from "react";

const dashboardRoutes = [
  {
    path: "/dashboard",
    component: lazy(() => import("../views/pages/Dashboard")),
    exact: true,
  },
  {
    path: "/dashboard/sales-order",
    component: lazy(() => import("../views/pages/SalesOrder")),
    exact: true,
  },
];

export default dashboardRoutes;
