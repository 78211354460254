import fetchUtil from "../utils/fetchUtil";

const statisticServices = {
  getAdminGraph: function (startDate, endDate, group) {
    return fetchUtil.get(`${process.env.REACT_APP_API_URL}/admin/graph`, {
      params: {
        start: startDate,
        end: endDate,
        group,
      },
    });
  },
};

export default statisticServices;
