import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { useHistory } from "react-router";
import "moment/locale/id";
import "antd/dist/antd.css";

import {
  AuthRoutes,
  DashboardRoutes,
  TransactionManagementRoutes,
  ProductManagementRoutes,
  VoucherManagementRoutes,
  ProductRefundRoutes,
  HomePageManagementRoutes,
  CustomerPageManagementRoutes,
  AdminManagementRoutes,
  PromoMangamentRoutes,
  MembershipManagementRoutes,
  CustomerSaidManagementRoutes,
  ArticleManagement,
  RatingReviewRoutes,
  NotificationBlastRoutes,
  NotificationCenterRoutes,
  NotificationCenterDetailRoutes
} from "./routes";
import { Preloader } from "./components";
// import fetchUtil from "./utils/fetchUtil";
import createStockAllStepRoutes from "./routes/createStockAllStepRoutes";

const PublicLayout = lazy(() => import("./views/layouts/PublicLayout"));
const ProtectedLayout = lazy(() => import("./views/layouts/ProtectedLayout"));

function App() {
  const history = useHistory();

  // fetchUtil.interceptors.response.use(
  //   (response) => {
  //     return response;
  //   },
  //   (error) => {
  //     if (error.response.status === 401) {
  //       history.push("/");
  //     }
  //     return error;
  //   }
  // );

  return (
    <Suspense fallback={<Preloader />}>
      <Switch>
        {AuthRoutes.map((route) => {
          const { component: Component, path, exact } = route;
          return (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={(props) => (
                <PublicLayout>
                  <Component {...props} />
                </PublicLayout>
              )}
            />
          );
        })}

        {DashboardRoutes.map((route) => {
          const { component: Component, path, exact } = route;
          return (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={(props) => (
                <ProtectedLayout>
                  <Component {...props} />
                </ProtectedLayout>
              )}
            />
          );
        })}

        {TransactionManagementRoutes.map((route) => {
          const { component: Component, path, exact } = route;
          return (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={(props) => (
                <ProtectedLayout>
                  <Component {...props} />
                </ProtectedLayout>
              )}
            />
          );
        })}

        {ProductManagementRoutes.map((route) => {
          const { component: Component, path, exact } = route;
          return (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={(props) => (
                <ProtectedLayout>
                  <Component {...props} />
                </ProtectedLayout>
              )}
            />
          );
        })}

        {VoucherManagementRoutes.map((route) => {
          const { component: Component, path, exact } = route;
          return (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={(props) => (
                <ProtectedLayout>
                  <Component {...props} />
                </ProtectedLayout>
              )}
            />
          );
        })}

        {HomePageManagementRoutes.map((route) => {
          const { component: Component, path, exact } = route;
          return (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={(props) => (
                <ProtectedLayout>
                  <Component {...props} />
                </ProtectedLayout>
              )}
            />
          );
        })}

        {CustomerPageManagementRoutes.map((route) => {
          const { component: Component, path, exact } = route;
          return (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={(props) => (
                <ProtectedLayout>
                  <Component {...props} />
                </ProtectedLayout>
              )}
            />
          );
        })}

        {MembershipManagementRoutes.map((route) => {
          const { component: Component, path, exact } = route;
          return (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={(props) => (
                <ProtectedLayout>
                  <Component {...props} />
                </ProtectedLayout>
              )}
            />
          );
        })}
        {MembershipManagementRoutes.map((route) => {
          const { component: Component, path, exact } = route;
          return (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={(props) => (
                <ProtectedLayout>
                  <Component {...props} />
                </ProtectedLayout>
              )}
            />
          );
        })}
        {CustomerSaidManagementRoutes.map((route) => {
          const { component: Component, path, exact } = route;
          return (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={(props) => (
                <ProtectedLayout>
                  <Component {...props} />
                </ProtectedLayout>
              )}
            />
          );
        })}
        {ArticleManagement.map((route) => {
          const { component: Component, path, exact } = route;
          return (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={(props) => (
                <ProtectedLayout>
                  <Component {...props} />
                </ProtectedLayout>
              )}
            />
          );
        })}
        {RatingReviewRoutes.map((route) => {
          const { component: Component, path, exact } = route;
          return (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={(props) => (
                <ProtectedLayout>
                  <Component {...props} />
                </ProtectedLayout>
              )}
            />
          );
        })}

        {AdminManagementRoutes.map((route) => {
          const { component: Component, path, exact } = route;
          return (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={(props) => (
                <ProtectedLayout>
                  <Component {...props} />
                </ProtectedLayout>
              )}
            />
          );
        })}

        {PromoMangamentRoutes.map((route) => {
          const { component: Component, path, exact } = route;
          return (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={(props) => (
                <ProtectedLayout>
                  <Component {...props} />
                </ProtectedLayout>
              )}
            />
          );
        })}

        {ProductRefundRoutes.map((route) => {
          const { component: Component, path, exact } = route;
          return (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={(props) => (
                <ProtectedLayout>
                  <Component {...props} />
                </ProtectedLayout>
              )}
            />
          );
        })}

        {createStockAllStepRoutes.map((route) => {
          const { component: Component, path, exact } = route;
          return (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={(props) => (
                <ProtectedLayout>
                  <Component {...props} />
                </ProtectedLayout>
              )}
            />
          );
        })}

        {NotificationBlastRoutes.map((route) => {
          const { component: Component, path, exact } = route;
          return (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={(props) => (
                <ProtectedLayout>
                  <Component {...props} />
                </ProtectedLayout>
              )}
            />
          );
        })}

        {NotificationCenterRoutes.map((route) => {
          const { component: Component, path, exact } = route;
          return (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={(props) => (
                <ProtectedLayout>
                  <Component {...props} />
                </ProtectedLayout>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

export default App;
