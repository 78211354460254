import actions from "./actions";

const initialState = {
  error: null,
  isLoading: false,
  isDeleted: false,
  isCreated: false,
  isUploaded: false,
  isDownloaded: false,
  vouchers: [],
  voucher: {},
  totalPages: 0,
  blobImage: new Blob(),
};

export default function assessmentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_VOUCHER_LIST:
      return { ...state, ...action.payload };
    case actions.SET_VOUCHER_DETAIL_BY_ID:
      return { ...state, ...action.payload };
    case actions.SET_CREATE_VOUCHER:
      return { ...state, ...action.payload };
    case actions.SET_DELETE_VOUCHER_BY_ID:
      return { ...state, ...action.payload };
    case actions.SET_UPLOAD_VOUCHER_IMAGE:
      return { ...state, ...action.payload };
    case actions.SET_VOUCHER_IMAGE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
