import actions from "./actions";

const initialState = {
  isLoading: false,
  error: null,
  salesOrders: [],
};

export default function assessmentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_ADMIN_GRAPH:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
