import { all, put, takeEvery, call } from "redux-saga/effects";

import actions from "./actions";
import { transactionServices } from "../../../services";

export function* GET_TRANSACTION_LIST({ payload }) {
  const { limit, currentPage, sort, startDate, endDate, typeOrder } = payload;

  yield put({ type: actions.SET_TRANSACTION_LIST, payload: { isLoading: true } });

  try {
    const { data } = yield call(
      transactionServices.getList,
      limit,
      currentPage,
      sort,
      startDate,
      endDate,
      typeOrder
    );

    yield put({
      type: actions.SET_TRANSACTION_LIST,
      payload: { transactions: data.data.order_list, totalPages: data.data.page },
    });
  } catch ({ message: error }) {
    yield put({ type: actions.SET_TRANSACTION_LIST, payload: { error } });
  } finally {
    yield put({ type: actions.SET_TRANSACTION_LIST, payload: { isLoading: false } });
  }
}

export function* GET_TRANSACTION_DETAIL(action) {
  yield put({ type: actions.SET_TRANSACTION_DETAIL, payload: { isLoading: true } });

  try {
    const { data } = yield call(transactionServices.getDetailByOrderID, action.payload.orderId);

    yield put({ type: actions.SET_TRANSACTION_DETAIL, payload: { transaction: data.data } });
  } catch ({ message: error }) {
    yield put({ type: actions.SET_TRANSACTION_DETAIL, payload: { error } });
  } finally {
    yield put({ type: actions.SET_TRANSACTION_DETAIL, payload: { isLoading: false } });
  }
}

export default function* transactionSaga() {
  yield all([
    takeEvery(actions.GET_TRANSACTION_LIST, GET_TRANSACTION_LIST),
    takeEvery(actions.GET_TRANSACTION_DETAIL, GET_TRANSACTION_DETAIL),
  ]);
}
