import { lazy } from "react";

const notificationCenterRoutes = [
  {
    path: "/notification-center",
    component: lazy(() => import("../views/pages/NotificationCenter/index.jsx")),
    exact: true,
  },
  {
    path: "/notification-center/detail/:id",
    component: lazy(() => import("../views/pages/NotificationCenterDetail/index")),
    exact: true,
  },
];

export default notificationCenterRoutes;
