import { all, put, takeEvery, call, takeLatest } from "redux-saga/effects";

import actions from "./actions";
import { voucherServices } from "../../../services";

export function* GET_VOUCHER_LIST({ payload }) {
  const { limit, currentPage, startDate, endDate, sort, searchKeyword } = payload;

  yield put({
    type: actions.SET_VOUCHER_LIST,
    payload: { isLoading: true, voucher: {}, blobImage: new Blob() },
  });

  try {
    const { data } = yield call(
      voucherServices.getList,
      limit,
      currentPage,
      startDate,
      endDate,
      sort,
      searchKeyword
    );

    yield put({
      type: actions.SET_VOUCHER_LIST,
      payload: { vouchers: data.result, totalPages: data.total_pages },
    });
  } catch ({ message: error }) {
    yield put({ type: actions.SET_VOUCHER_LIST, payload: { error } });
  } finally {
    yield put({ type: actions.SET_VOUCHER_LIST, payload: { isLoading: false } });
  }
}

export function* GET_VOUCHER_DETAIL_BY_ID({ payload }) {
  const { id } = payload;

  yield put({
    type: actions.SET_VOUCHER_DETAIL_BY_ID,
    payload: { isLoading: true, blobImage: new Blob() },
  });

  try {
    const { data } = yield call(voucherServices.getDetailByID, id);

    yield put({
      type: actions.SET_VOUCHER_DETAIL_BY_ID,
      payload: { voucher: data.result },
    });
  } catch ({ message: error }) {
    yield put({ type: actions.SET_VOUCHER_DETAIL_BY_ID, payload: { error } });
  } finally {
    yield put({ type: actions.SET_VOUCHER_DETAIL_BY_ID, payload: { isLoading: false } });
  }
}

export function* DELETE_VOUCHER_BY_ID({ payload }) {
  const { id } = payload;

  yield put({ type: actions.SET_DELETE_VOUCHER_BY_ID, payload: { isLoading: true } });

  try {
    yield call(voucherServices.deleteByID, id);

    yield put({ type: actions.SET_VOUCHER_DETAIL_BY_ID, payload: { isDeleted: true } });
  } catch ({ message: error }) {
    yield put({ type: actions.SET_DELETE_VOUCHER_BY_ID, payload: { error, isDeleted: false } });
  } finally {
    yield put({ type: actions.SET_DELETE_VOUCHER_BY_ID, payload: { isLoading: false } });
  }
}

export function* CREATE_VOUCHER({ payload }) {
  yield put({ type: actions.SET_CREATE_VOUCHER, payload: { isLoading: true } });

  try {
    const { data } = yield call(voucherServices.create, payload);

    yield put({
      type: actions.SET_VOUCHER_DETAIL_BY_ID,
      payload: { voucher: data.result, isCreated: true },
    });
  } catch ({ message: error }) {
    yield put({ type: actions.SET_CREATE_VOUCHER, payload: { error, isCreated: false } });
  } finally {
    yield put({ type: actions.SET_CREATE_VOUCHER, payload: { isLoading: false } });
  }
}

export function* UPLOAD_VOUCHER_IMAGE({ payload }) {
  const { id, data } = payload;

  yield put({ type: actions.SET_UPLOAD_VOUCHER_IMAGE, payload: { isLoading: true } });

  try {
    yield call(voucherServices.uploadImage, id, data);

    yield put({ type: actions.SET_UPLOAD_VOUCHER_IMAGE, payload: { isUploaded: true } });
  } catch ({ message: error }) {
    yield put({ type: actions.SET_UPLOAD_VOUCHER_IMAGE, payload: { error, isUploaded: false } });
  } finally {
    yield put({ type: actions.SET_UPLOAD_VOUCHER_IMAGE, payload: { isLoading: false } });
  }
}

export function* GET_VOUCHER_IMAGE({ payload }) {
  const { imageId } = payload;

  yield put({ type: actions.SET_VOUCHER_IMAGE, payload: { isLoading: true, isDownloaded: false } });

  try {
    const { data } = yield call(voucherServices.downloadImage, imageId);

    yield put({
      type: actions.SET_VOUCHER_IMAGE,
      payload: { blobImage: data, isDownloaded: true },
    });
  } catch ({ message: error }) {
    yield put({ type: actions.SET_VOUCHER_IMAGE, payload: { error } });
  } finally {
    yield put({ type: actions.SET_VOUCHER_IMAGE, payload: { isLoading: false } });
  }
}

export default function* voucherSaga() {
  yield all([
    takeEvery(actions.GET_VOUCHER_LIST, GET_VOUCHER_LIST),
    takeLatest(actions.GET_VOUCHER_DETAIL_BY_ID, GET_VOUCHER_DETAIL_BY_ID),
    takeEvery(actions.DELETE_VOUCHER_BY_ID, DELETE_VOUCHER_BY_ID),
    takeEvery(actions.CREATE_VOUCHER, CREATE_VOUCHER),
    takeEvery(actions.UPLOAD_VOUCHER_IMAGE, UPLOAD_VOUCHER_IMAGE),
    takeEvery(actions.GET_VOUCHER_IMAGE, GET_VOUCHER_IMAGE),
  ]);
}
