import { createSlice } from "@reduxjs/toolkit";
import { getListMaterial } from "./material.api";




const materialSlice = createSlice({
    name: 'material',
    initialState: {
        loading: false,
        listMaterial: []
    },
    extraReducers: {
        [getListMaterial.pending]: (state, action) => {
            state.loading = true
        },
        [getListMaterial.fulfilled]: (state, { payload }) => {
            state.loading = false
            console.log(payload.data, 'listMaterial')
            state.listMaterial = payload.data
        },
        [getListMaterial.rejected]: (state, action) => {
            state.loading = false
        },
    }

})

export default materialSlice.reducer