import { createSlice } from "@reduxjs/toolkit";
import { getListWarehouse, getDetailWarehouse } from "./warehouse.api";

const warehouseSlice = createSlice({
  name: "warehouse",
  initialState: {
    loading: true,
    listWarehouse: [],
    warehouseOptions: [],
    totalData:0,
    detailWarehouse:{}
  },
  extraReducers: {
    [getListWarehouse.pending]: (state, action) => {
      state.loading = true;
    },
    [getListWarehouse.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listWarehouse = payload.data;
      const mappingWarehouse = payload.data.reduce((filtered, option) => {
        const opt = { value: option.id, label: option.name };
        filtered.push(opt);
        return filtered;
      }, []);
      state.warehouseOptions = mappingWarehouse;
      state.totalData = payload.total_data
    },
    [getListWarehouse.rejected]: (state, action) => {
      state.loading = false;
    },
    [getDetailWarehouse.pending]: (state, action) => {
      state.loading = true;
    },
    [getDetailWarehouse.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.detailWarehouse = payload.data;
    },
    [getDetailWarehouse.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default warehouseSlice.reducer;
