import { lazy } from "react";

const customerPageManagementRoutes = [
  {
    path: "/customer-management",
    component: lazy(() => import("../views/pages/CustomerManagement")),
    exact: true,
  },
  {
    path: "/customer-management/detail/:id",
    component: lazy(() => import("../views/pages/CustomerManagement/DetailCustomer/index")),
    exact: true,
  },
  {
    path: "/customer-management/transaksi/detail",
    component: lazy(() =>
      import("../views/pages/CustomerManagement/DetailTransaksiCustomer/index")
    ),
    exact: true,
  },
];

export default customerPageManagementRoutes;
