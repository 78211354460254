import { createSlice } from "@reduxjs/toolkit";
import {
  getListProduct,
  getListProductAccessories,
  getListProductTires,
  getListProductWheels,
  getListProductSuspension,
  getListProductExhaust,
  variantBodyCreate,
  parrentBodyCreate,
  destroyBody,
  replaceVariantBody,
  editedIndex,
  editVariantBodyCreate,
} from "./product.api";

const productSlice = createSlice({
  name: "product",
  initialState: {
    loading: false,
    listProduct: [],
    listProductAccessories: [],
    listProductTires: [],
    listProductWheels: [],
    listProductWheelsTotal: [],
    listProductSuspension: [],
    listProductExhaust: [],
    totalItemAcc: 0,
    totalPageAcc: 0,
    totalItemWheels: 0,
    totalPageWheels: 0,
    totalItemTires: 0,
    totalPageTires: 0,
    totalItemSuspension: 0,
    totalPageSuspension: 0,
    totalItemExhaust: 0,
    totalPageExhaust: 0,
    variantProduct: [],
    editedIndexVariant: {},
    parentProduct: {},
  },
  extraReducers: {
    [getListProduct.pending]: (state, action) => {
      state.loading = true;
    },
    [getListProduct.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listProduct = payload.data.products;
    },
    [getListProduct.rejected]: (state, action) => {
      state.loading = false;
    },
    //Accesoris
    [getListProductAccessories.pending]: (state, action) => {
      state.loading = true;
    },
    [getListProductAccessories.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listProductAccessories = payload.data.products;
      state.totalItemAcc = payload.data.total_item;
      state.totalPageAcc = payload.data.total_page;
    },
    [getListProductAccessories.rejected]: (state, action) => {
      state.loading = false;
    },

    //Tires
    [getListProductTires.pending]: (state, action) => {
      state.loading = true;
    },
    [getListProductTires.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listProductTires = payload.data.products;
      state.totalItemTires = payload.data.total_item;
      state.totalPageTires = payload.data.total_page;
    },
    [getListProductTires.rejected]: (state, action) => {
      state.loading = false;
    },

    //Wheels
    [getListProductWheels.pending]: (state, action) => {
      state.loading = true;
    },
    [getListProductWheels.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listProductWheels = payload.data.products;
      state.totalItemWheels = payload.data.total_item;
      state.totalPageWheels = payload.data.total_page;
    },
    [getListProductWheels.rejected]: (state, action) => {
      state.loading = false;
    },

    //Exhaust
    [getListProductExhaust.pending]: (state, action) => {
      state.loading = true;
    },
    [getListProductExhaust.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listProductExhaust = payload.data.products;
      state.totalItemExhaust = payload.data.total_item;
      state.totalPageExhaust = payload.data.total_page;
    },
    [getListProductExhaust.rejected]: (state, action) => {
      state.loading = false;
    },

    //Suspension
    [getListProductSuspension.pending]: (state, action) => {
      state.loading = true;
    },
    [getListProductSuspension.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listProductSuspension = payload.data.products;
      state.totalItemSuspension = payload.data.total_item;
      state.totalPageSuspension = payload.data.total_page;
    },
    [getListProductSuspension.rejected]: (state, action) => {
      state.loading = false;
    },

    //variant body
    [variantBodyCreate.pending]: (state, action) => {
      state.loading = true;
    },
    [variantBodyCreate.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.variantProduct = [...state.variantProduct, payload];
    },
    [variantBodyCreate.rejected]: (state, action) => {
      state.loading = false;
    },

    //replace variant body
    [replaceVariantBody.pending]: (state, action) => {
      state.loading = true;
    },
    [replaceVariantBody.fulfilled]: (state, payload) => {
      state.loading = false;
      state.variantProduct = [...payload.meta.arg];
    },
    [replaceVariantBody.rejected]: (state, action) => {
      state.loading = false;
    },

    //replace variant body
    [editVariantBodyCreate.pending]: (state, action) => {
      state.loading = true;
    },
    [editVariantBodyCreate.fulfilled]: (state, {payload}) => {
      console.log(payload);
      state.loading = false;
      const newBodyVariant = [...state.variantProduct]
      newBodyVariant.splice(payload.index, 1, payload.payload);
      state.variantProduct = newBodyVariant;
    },
    [editVariantBodyCreate.rejected]: (state, action) => {
      state.loading = false;
    },

    //parent body
    [parrentBodyCreate.pending]: (state, action) => {
      state.loading = true;
    },
    [parrentBodyCreate.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.parentProduct = payload;
    },
    [parrentBodyCreate.rejected]: (state, action) => {
      state.loading = false;
    },

    //destroyBody
    [destroyBody.pending]: (state, action) => {
      state.loading = true;
    },
    [destroyBody.fulfilled]: (state, { payload }) => {
      const emptyVariant = [];
      const emptyProduct = {};
      state.loading = false;
      state.variantProduct = emptyVariant;
      state.parentProduct = emptyProduct;
    },
    [destroyBody.rejected]: (state, action) => {
      state.loading = false;
    },

    //edited index
    [editedIndex.pending]: (state, action) => {
      state.loading = true;
    },
    [editedIndex.fulfilled]: (state, payload) => {
      console.log(payload);
      // state.editedIndexVariant = payload;
    },
    [editedIndex.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default productSlice.reducer;
