// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-table-thead tr th {
  font-weight: bold !important;
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.form-size--two {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.25rem;
  column-gap: 1.25rem;
  align-items: start;
}

.img-list {
  width: 100% !important;
  height: 500px;
  object-fit: cover;
  object-position: 50% 50%;
}

.textarea-review {
  background-color: #fafafa;
  padding: 1.5rem;
  border-radius: 10px;
  min-height: 150px;
}
.textarea-review p {
  font-size: 18px;
  font-weight: 700;
  color: #454545;
}

.object-fit-cover {
  object-fit: cover;
}

.refund-product-mini-card {
  background-color: #efefef;
  border-radius: 10px;
  width: -webkit-max-content;
  width: max-content;
}
.refund-product-mini-card .brand_name {
  color: #e9522a !important;
  font-size: 15px;
  font-weight: 700;
}
.refund-product-mini-card .product_name {
  color: #455673 !important;
  font-size: 20px;
  font-weight: 700;
}

.text-orange {
  color: #e9522a;
}

img.ant-image-preview-img {
  display: inline-block !important;
}

img.ant-image-img {
  object-fit: cover;
  border-radius: 10px;
}

.icon-btn span {
  display: inline-flex;
}`, "",{"version":3,"sources":["webpack://./src/styles/global.scss"],"names":[],"mappings":"AAEI;EACE,4BAAA;EACA,mBAAA;AADN;;AAMA;EACE,eAAA;AAHF;;AAMA;EACE,aAAA;EACA,qCAAA;EACA,wBAAA;EACA,mBAAA;EACA,kBAAA;AAHF;;AAMA;EACE,sBAAA;EACA,aAAA;EACA,iBAAA;EACA,wBAAA;AAHF;;AAMA;EACE,yBAAA;EACA,eAAA;EACA,mBAAA;EACA,iBAAA;AAHF;AAIE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAFJ;;AAMA;EACE,iBAAA;AAHF;;AAMA;EACE,yBAAA;EACA,mBAAA;EACA,0BAAA;EAAA,kBAAA;AAHF;AAIE;EACE,yBAAA;EACA,eAAA;EACA,gBAAA;AAFJ;AAIE;EACE,yBAAA;EACA,eAAA;EACA,gBAAA;AAFJ;;AAMA;EACE,cAAA;AAHF;;AAMA;EACE,gCAAA;AAHF;;AAMA;EACE,iBAAA;EACA,mBAAA;AAHF;;AAOE;EACE,oBAAA;AAJJ","sourcesContent":[".ant-table-thead {\n  tr {\n    th {\n      font-weight: bold !important;\n      white-space: nowrap;\n    }\n  }\n}\n\n.cursor-pointer {\n  cursor: pointer;\n}\n\n.form-size--two {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-column-gap: 1.25rem;\n  column-gap: 1.25rem;\n  align-items: start;\n}\n\n.img-list {\n  width: 100% !important;\n  height: 500px;\n  object-fit: cover;\n  object-position: 50% 50%;\n}\n\n.textarea-review {\n  background-color: #fafafa;\n  padding: 1.5rem;\n  border-radius: 10px;\n  min-height: 150px;\n  p {\n    font-size: 18px;\n    font-weight: 700;\n    color: #454545;\n  }\n}\n\n.object-fit-cover {\n  object-fit: cover;\n}\n\n.refund-product-mini-card {\n  background-color: #efefef;\n  border-radius: 10px;\n  width: max-content;\n  .brand_name {\n    color: #e9522a !important;\n    font-size: 15px;\n    font-weight: 700;\n  }\n  .product_name {\n    color: #455673 !important;\n    font-size: 20px;\n    font-weight: 700;\n  }\n}\n\n.text-orange {\n  color: #e9522a;\n}\n\nimg.ant-image-preview-img {\n  display: inline-block !important;\n}\n\nimg.ant-image-img {\n  object-fit: cover;\n  border-radius: 10px;\n}\n\n.icon-btn {\n  span {\n    display: inline-flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
