import { lazy } from "react";

const createStockAllStepRoutes = [
  //article
  {
    path: "/article-list",
    component: lazy(() => import("../views/pages/stockPages/article/ArticleList")),
    exact: true,
  },
  {
    path: "/article-list/form",
    component: lazy(() => import("../views/pages/stockPages/article/ArticleForm")),
    exact: true,
  },
  {
    path: "/article/:id",
    component: lazy(() => import("../views/pages/stockPages/article/ArticleForm")),
    exact: true,
  },
  //brand
  {
    path: "/brand-list",
    component: lazy(() => import("../views/pages/stockPages/brand/BrandList")),
    exact: true,
  },
  {
    path: "/brand-list/form",
    component: lazy(() => import("../views/pages/stockPages/brand/BrandForm")),
    exact: true,
  },
  {
    path: "/brand-list/form/:id",
    component: lazy(() => import("../views/pages/stockPages/brand/BrandFormEdit")),
    exact: true,
  },
  {
    path: "/brand/:id",
    component: lazy(() => import("../views/pages/stockPages/brand/brandDetail.jsx")),
    exact: true,
  },
  //car
  {
    path: "/car-list",
    component: lazy(() => import("../views/pages/stockPages/car/CarList")),
    exact: true,
  },
  {
    path: "/car-list/form",
    component: lazy(() => import("../views/pages/stockPages/car/CarForm")),
    exact: true,
  },
  {
    path: "/car-list/:id",
    component: lazy(() => import("../views/pages/stockPages/car/DetailCar/index")),
    exact: true,
  },
  {
    path: "/car-list/edit/:id",
    component: lazy(() => import("../views/pages/stockPages/car/DetailCar/EditCar")),
    exact: true,
  },
  //category
  {
    path: "/category-list",
    component: lazy(() => import("../views/pages/stockPages/category/CategoryList")),
    exact: true,
  },
  {
    path: "/category-list/form",
    component: lazy(() => import("../views/pages/stockPages/category/CategoryForm")),
    exact: true,
  },
  //dealer
  {
    path: "/dealer-list",
    component: lazy(() => import("../views/pages/stockPages/dealer/DealerList")),
    exact: true,
  },
  {
    path: "/dealer/detail/:id",
    component: lazy(() => import("../views/pages/stockPages/dealer/DetailDealer/index")),
    exact: true,
  },
  {
    path: "/dealer-list/form",
    component: lazy(() => import("../views/pages/stockPages/dealer/DealerForm")),
    exact: true,
  },
  {
    path: "/dealer/detail/edit/:id",
    component: lazy(() => import("../views/pages/stockPages/dealer/DetailDealer/EditForm")),
    exact: true,
  },
  {
    path: "/dealer/addons/edit/:id",
    component: lazy(() => import("../views/pages/stockPages/dealer/EditAddons/index")),
    exact: true,
  },
  //product
  {
    path: "/product-list",
    component: lazy(() => import("../views/pages/stockPages/product/ProductList")),
    exact: true,
  },
  {
    path: "/product-list/form",
    component: lazy(() => import("../views/pages/stockPages/product/ProductForm")),
    exact: true,
  },
  //material
  {
    path: "/material-list",
    component: lazy(() => import("../views/pages/stockPages/material/MaterialList")),
    exact: true,
  },
  {
    path: "/material-list/form",
    component: lazy(() => import("../views/pages/stockPages/material/MaterialForm")),
    exact: true,
  },
  //variant
  {
    path: "/variant-list",
    component: lazy(() => import("../views/pages/stockPages/variant/VariantList")),
    exact: true,
  },
  {
    path: "/variant-list/form",
    component: lazy(() => import("../views/pages/stockPages/variant/VariantForm")),
    exact: true,
  },
  //warehouse
  {
    path: "/warehouse-list",
    component: lazy(() => import("../views/pages/stockPages/warehouse/WarehouseList")),
    exact: true,
  },
  {
    path: "/warehouse-detail/:id",
    component: lazy(() => import("../views/pages/stockPages/warehouse/detail/WarehouseDetail")),
    exact: true,
  },
  {
    path: "/warehouse-list/form",
    component: lazy(() => import("../views/pages/stockPages/warehouse/WarehouseForm")),
    exact: true,
  },
  {
    path: "/warehouse/edit/form/:id",
    component: lazy(() => import("../views/pages/stockPages/warehouse/edit/EditWarehouseForm")),
    exact: true,
  },
  {
    path: "/warehouse/:id",
    component: lazy(() => import("../views/pages/stockPages/warehouse/WarehouseForm")),
    exact: true,
  },
  //galery
  {
    path: "/galery-list",
    component: lazy(() => import("../views/pages/stockPages/galery/GaleryList")),
    exact: true,
  },
  {
    path: "/galery-list/form",
    component: lazy(() => import("../views/pages/stockPages/galery/GaleryForm")),
    exact: true,
  },
  // prod to post
  {
    path: "/product-post-list",
    component: lazy(() => import("../views/pages/stockPages/productToPost/ProductToPostList")),
    exact: true,
  },
  {
    path: "/product-post-list/form",
    component: lazy(() => import("../views/pages/stockPages/productToPost/ProductToPostForm")),
    exact: true,
  },
];

export default createStockAllStepRoutes;
