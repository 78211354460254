import { all, put, takeEvery, call } from "redux-saga/effects";

import actions from "./actions";
import { authServices } from "../../../services";

export function* DO_LOGIN({ payload }) {
  yield put({ type: actions.SET_LOGIN, payload: { isLoading: true } });

  try {
    const { data } = yield call(authServices.login, payload);
    yield put({
      type: actions.SET_LOGIN,
      payload: { accessToken: data.data.accessToken },
    });
  } catch (error) {
    yield put({ type: actions.SET_LOGIN, payload: { error: error.response.data.response_data } });
  } finally {
    yield put({ type: actions.SET_LOGIN, payload: { isLoading: false } });
  }
}

export default function* authSaga() {
  yield all([takeEvery(actions.DO_LOGIN, DO_LOGIN)]);
}
