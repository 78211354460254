import { createSlice } from "@reduxjs/toolkit";
import { getListProvince } from "./province.api";

const provinceSlice = createSlice({
    name: 'province',
    initialState: {
        loading: false,
        listProvince: [],
        provinceOptions: []
    },
    extraReducers: {
        [getListProvince.pending]: (state, action) => {
            state.loading = true
        },
        [getListProvince.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.listProvince = payload.data
            const mappingProvince = payload.data.reduce((filtered, option) => {
                const opt = { label: option.name, value: option.id }
                filtered.push(opt)
                return filtered
            }, [])
            state.provinceOptions = mappingProvince
        },
        [getListProvince.rejected]: (state, action) => {
            state.loading = false
        },
    }
})

export default provinceSlice.reducer