import { createSlice } from "@reduxjs/toolkit";
import { getSetting } from "./settingEpc.api";

const settingSlice = createSlice({
    name: "setting",
    initialState: {
        isLoading: false,
        settingData: null,
        error: null,
    },
    extraReducers: {
        [getSetting.pending]: (state) => {
            state.isLoading = true;
            state.error = null; 
        },
        [getSetting.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.settingData = payload.data;
        },
        [getSetting.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.error = payload; 
        },
    },
});

export default settingSlice.reducer;