import { createSlice } from "@reduxjs/toolkit";
import { getListCar, getDetailCar } from "./car.api";

const carSlice = createSlice({
  name: "car",
  initialState: {
    loading: false,
    listCar: [],
    carList: [],
    listDetailCar: [],
    listDetailCarBrand: [],
    listDetailCarVelgs: [],
    listDetailCarTires: [],
    totalPageCar:0,
    totalItemCar:0
  },
  extraReducers: {
    [getListCar.pending]: (state, action) => {
      state.loading = true;
    },
    [getListCar.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listCar = payload.data.cars;
      state.carList = payload.data;
      state.totalPageCar = payload.data.total_page;
      state.totalItemCar = payload.data.total_item;
    },
    [getListCar.rejected]: (state, action) => {
      state.loading = false;
    },
    [getDetailCar.pending]: (state, action) => {
      state.loading = true;
    },
    [getDetailCar.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listDetailCar = payload.data;
      state.listDetailCarBrand = payload.data.brand.name;
      state.listDetailCarVelgs = payload.data.velgs;
      state.listDetailCarTires = payload.data.tires;
    },
    [getDetailCar.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default carSlice.reducer;
