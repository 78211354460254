import classNames from "classnames";
import React from "react";

import "./styles.scss";

const Button = ({
  children,
  widthVariant,
  disabled,
  textVariant,
  variant,
  onClick,
  heightVariant,
  type,
  icon,
  colorVariant,
  isLoading,
  className,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        "button",
        {
          "button__width-variant--full": widthVariant === "full",
          "button__variant--delete-table": variant === "delete-table",
          "button__variant--delete-voucher": variant === "delete-voucher",
          "button__variant--delete": variant === "delete",
          "button__variant--big": variant === "big",
          "button__variant--secondary": variant === "secondary",
          "button__variant--grey": variant === "grey",
          "button__variant--white": variant === "white",
          "button__variant--transparent": variant === "transparent",
          "button__text-variant--big": textVariant === "big",
          "button__height-variant--input": heightVariant === "input",
          "button__variant--delete-badge": variant === "delete-badge",
          "button__variant--primary": variant === "primary",
          "button__variant--transparent-orange": variant === "transparent-orange",
          button__icon: icon != null,
          "button__color-variant--red": colorVariant === "red",
          button__loading: isLoading,
        },
        className
      )}
    >
      {isLoading ? (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <>
          {(variant === "delete" ||
            variant === "delete-table" ||
            variant === "delete-badge" ||
            variant === "delete-voucher") && (
            <svg
              width="24"
              height="24"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.2012 15.5L10.2012 12.5"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M14.2012 15.5L14.2012 12.5"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M3.20117 7.5H21.2012V7.5C20.2693 7.5 19.8033 7.5 19.4358 7.65224C18.9457 7.85523 18.5564 8.24458 18.3534 8.73463C18.2012 9.10218 18.2012 9.56812 18.2012 10.5V16.5C18.2012 18.3856 18.2012 19.3284 17.6154 19.9142C17.0296 20.5 16.0868 20.5 14.2012 20.5H10.2012C8.31555 20.5 7.37274 20.5 6.78696 19.9142C6.20117 19.3284 6.20117 18.3856 6.20117 16.5V10.5C6.20117 9.56812 6.20117 9.10218 6.04893 8.73463C5.84594 8.24458 5.45659 7.85523 4.96654 7.65224C4.599 7.5 4.13306 7.5 3.20117 7.5V7.5Z"
                strokeWidth="2"
                stroke="currentColor"
                strokeLinecap="round"
              />
              <path
                d="M10.2693 3.87059C10.3833 3.76427 10.6344 3.67033 10.9836 3.60332C11.3329 3.53632 11.7609 3.5 12.2012 3.5C12.6414 3.5 13.0694 3.53632 13.4187 3.60332C13.768 3.67033 14.0191 3.76427 14.133 3.87059"
                strokeWidth="2"
                stroke="currentColor"
                strokeLinecap="round"
              />
            </svg>
          )}
          {variant === "transparent" && (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 4.5V19.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
              <path d="M4.5 12H19.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            </svg>
          )}
          {icon === "upload" && (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5 10.5L11.5 4.5M11.5 4.5L5.5 10.5M11.5 4.5L11.5 19.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          <span>{children}</span>
        </>
      )}
    </button>
  );
};

export default Button;
