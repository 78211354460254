import { lazy } from "react";

const ratingReviewRoutes = [
  {
    path: "/rating-review-management",
    component: lazy(() => import("../views/pages/RatingReviewManagament/index")),
    exact: true,
  },
  {
    path: "/rating-review-management/detail/:id",
    component: lazy(() => import("../views/pages/RatingReviewManagament/DetailRatingReview/index")),
    exact: true,
  },
  {
    path: "/rating-review-management/edit/:id",
    component: lazy(() =>
      import("../views/pages/RatingReviewManagament/DetailRatingReview/EditRatingReview")
    ),
    exact: true,
  },
];

export default ratingReviewRoutes;
