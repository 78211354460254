import { createSlice } from "@reduxjs/toolkit";
import { postRefreshToken } from "./refreshToken.api";


const refreshTokenSlice = createSlice({
    name : 'refresh-token',
    initialState : {
        refreshToken :{},
        loading : false,
        error: {}
    },
    extraReducers : {
        [postRefreshToken.pending]: (state, action)=>{
            state.loading = true
        }, 
        [postRefreshToken.fulfilled]: (state, {payload})=>{
            state.loading = false
        }, 
        [postRefreshToken.rejected]: (state, action)=>{
            state.loading = false
        },  
    }
})

export default refreshTokenSlice.reducer