import { createSlice } from "@reduxjs/toolkit";
import { getListDealer, getDetailDealer,updateDealer } from "./dealer.api";

const dealerSlice = createSlice({
    name: 'dealer',
    initialState: {
        loading: false,
        isUpdating:false,
        listDealer: [],
        detailDealer: {},
        totalPageDealer:0,
    },
    extraReducers: {
        [getListDealer.pending]: (state, action) => {
            state.loading = true
        },
        [getListDealer.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.listDealer = payload.data.dealers
            state.totalPageDealer = payload.data.total_page
        },
        [getListDealer.rejected]: (state, action) => {
            state.loading = false
        },
        [getDetailDealer.pending]: (state, action) => {
            state.loading = true
        },
        [getDetailDealer.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.detailDealer = payload.data
        },
        [getDetailDealer.rejected]: (state, action) => {
            state.loading = false
        },
        [updateDealer.pending]: (state, action) => {
            state.isUpdating = true
        },
        [updateDealer.fulfilled]: (state, { payload }) => {
            state.isUpdating = false
        },
        [updateDealer.rejected]: (state, action) => {
            state.isUpdating = false
        },
    }

})

export default dealerSlice.reducer