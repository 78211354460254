import React from "react";
import classNames from "classnames";

import "./styles.scss";

const Checkbox = ({
  fontWeight,
  fontSize,
  checked,
  onChange,
  required,
  disabled,
  children,
  textVariant,
}) => {
  return (
    <label className="checkbox">
      <div
        className="checkbox__toggler"
        style={{
          fontWeight: fontWeight ? fontWeight : "400",
          fontSize: fontSize ? fontSize : "14px",
        }}
      >
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          required={required}
          disabled={disabled}
        />

        <svg
          className={`checkbox__checkmark ${disabled && `checkbox--disabled`}`}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 7L9.00004 18L3.99994 13"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <span
        className={classNames("checkbox__label", {
          "checkbox__label--big": textVariant === "big",
        })}
      >
        {children}
      </span>
    </label>
  );
};

export default Checkbox;
