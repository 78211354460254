import { createSlice } from "@reduxjs/toolkit";
import { uploadFile } from "./fileService";

const uploadSlice = createSlice({
    name: 'upload',
    initialState: {
        isUploading: false,
    },
    extraReducers: {
        [uploadFile.pending]: (state, action) => {
            state.isUploading = true
        },
        [uploadFile.fulfilled]: (state, { payload }) => {
            state.isUploading = false
        },
        [uploadFile.rejected]: (state, action) => {
            state.isUploading = false
        },
    }

})

export default uploadSlice.reducer