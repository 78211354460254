import { lazy } from "react";

const homePageManagementRoutes = [
  {
    path: "/homepage-management",
    component: lazy(() => import("../views/pages/HomePageManagement")),
    exact: true,
  },
  {
    path: "/homepage-management/addBanners",
    component: lazy(() => import("../views/pages/HomePageManagement/Banners")),
    exact: true,
  },
  {
    path: "/homepage-management/addHotDeals",
    component: lazy(() => import("../views/pages/HomePageManagement/HotDeals")),
    exact: true,
  },
];

export default homePageManagementRoutes;
