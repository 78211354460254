import { createSlice } from '@reduxjs/toolkit';
import { getRatingReview, getDetailReview } from './ratingReview.api';

const ratingReviewSlice = createSlice({
  name: "review",
  initialState: {
    loading: false,
    listReview: [],
    totalPage:0,
    totalItem:0,
    detailReview: {}
  },
  extraReducers: {
    [getRatingReview.pending]: (state, action) => {
      state.loading = true;
    },
    [getRatingReview.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listReview = payload.data
      state.totalPage = payload.data.total_page
      state.totalItem = payload.data.total_item
    },
    [getRatingReview.rejected]: (state, action) => {
      state.loading = false
    },
    [getDetailReview.pending]: (state, action) => {
      state.loading = true;
    },
    [getDetailReview.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.detailReview = payload.data
      
    },
    [getDetailReview.rejected]: (state, action) => {
      state.loading = false
    },
  }
});

export default ratingReviewSlice.reducer;