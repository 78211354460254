import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getListArticle = createAsyncThunk('getListArticle/get', async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('accessToken')
    try {
        const response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/article`,
            // params: {
            //     order: 'created_at,ASC',
            //     limit: 10,
            //     offset: 0
            // },
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})

export const deleteArticle = createAsyncThunk('deleteArticle/delete', async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('accessToken')
    const { id } = data
    try {
        const response = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/article/${id}`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})

export const createArticle = createAsyncThunk('createArticle/create', async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('accessToken')
    try {
        const response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/article`,
            headers: {
                "Authorization": `Bearer ${token}`

            },
            data
        })
        return response.data
    } catch (error) {
        console.error(error.response, 'error response')
        return rejectWithValue(error)
    }
})

