import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getListTransaction = createAsyncThunk(
  "getListTransaction/get",
  async (params, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/transaction`,
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const getDetailTransaction = createAsyncThunk(
  "getDetailTransaction/get",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    const { id } = data;
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/transaction/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const processTransaction = createAsyncThunk(
  "processTransaction/post", // Method diubah menjadi POST
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    const id = data.order_id; // Menggunakan order_id dari data
    const payload = {
      warehouses: data.warehouses, // Payload yang sama dengan pickUpTransaction
    };

    try {
      const response = await axios({
        method: "post", // Method POST
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/transaction/process/${id}/v2`, // Endpoint baru
        data: payload, // Payload ditambahkan
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const pickUpTransaction = createAsyncThunk(
  "pickUpTransaction/post",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    const id = data.order_id; // Mengambil order_id dari data

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/transaction/${id}/pickup/v2`, // Endpoint baru
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); // Tidak ada payload yang dikirim
      return response.data;
    } catch (error) {
      console.log(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const listStockProduct = createAsyncThunk(
  "listStockProduct/post",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");

    // console.log(payload)
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/warehouse/stock/product`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      });
      return response.data;
    } catch (error) {
      console.log(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const getInvoice = createAsyncThunk(
  "getInvoice/get",
  async (orderId, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    const id = orderId;
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_ORDER_SERVICE_URL}/orders/${id}/invoice`,
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.log(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const getDispatchNote = createAsyncThunk(
  "getDispatchNote/get",
  async (orderId, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    const id = orderId;
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/transaction/${id}/dispatch-note`,
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.log(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);

export const getShippingLabel = createAsyncThunk(
  "getShippingLabel/get",
  async (orderId, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    const id = orderId;
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/transaction/${id}/shipping-label`,
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.log(error.response, "error response");
      return rejectWithValue(error);
    }
  }
);
