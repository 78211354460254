const actions = {
  GET_VOUCHER_LIST: "voucher/GET_VOUCHER_LIST",
  SET_VOUCHER_LIST: "voucher/SET_VOUCHER_LIST",
  GET_VOUCHER_DETAIL_BY_ID: "voucher/GET_VOUCHER_DETAIL_BY_ID",
  SET_VOUCHER_DETAIL_BY_ID: "voucher/SET_VOUCHER_DETAIL_BY_ID",
  DELETE_VOUCHER_BY_ID: "voucher/DELETE_VOUCHER_BY_ID",
  SET_DELETE_VOUCHER_BY_ID: "voucher/SET_DELETE_VOUCHER_BY_ID",
  CREATE_VOUCHER: "voucher/CREATE_VOUCHER",
  SET_CREATE_VOUCHER: "voucher/SET_CREATE_VOUCHER",
  UPLOAD_VOUCHER_IMAGE: "voucher/UPLOAD_VOUCHER_IMAGE",
  SET_UPLOAD_VOUCHER_IMAGE: "voucher/SET_UPLOAD_VOUCHER_IMAGE",
  GET_VOUCHER_IMAGE: "voucher/GET_VOUCHER_IMAGE",
  SET_VOUCHER_IMAGE: "voucher/SET_VOUCHER_IMAGE",
};

export default actions;
