import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../views/pages/Login/login.reducer";
import brandReducer from "../views/pages/stockPages/brand/brand.reducer";
import carReducer from "../views/pages/stockPages/car/car.reducer";
import categoryReducer from "../views/pages/stockPages/category/category.reducer";
import materialReducer from "../views/pages/stockPages/material/material.reducer";
import variantReducer from "../views/pages/stockPages/variant/variant.reducer";
import voucherReducer from "../views/pages/VoucherManagement/voucher.reducer";
import productReducer from "../views/pages/ProductManagement/product.reducer";
import detailProductReducer from "../views/pages/ProductManagement/detail.reducer";
import dealerReducer from "../views/pages/stockPages/dealer/dealer.reducer";
import districtReducer from "../views/pages/stockPages/region/district.reducer";
import regencyReducer from "../views/pages/stockPages/region/regency.reducer";
import warehouseReducer from "../views/pages/stockPages/warehouse/warehouse.reducer";
import bannerReducer from "../views/pages/HomePageManagement/components/Banners/banner.reducer";
import customerReducer from "../views/pages/CustomerManagement/components/customer.reducer";
import bannerReducerID from "../views/pages/HomePageManagement/components/Banners/bannerID.reducer";
import hotDealsReducer from "../views/pages/HomePageManagement/components/HotDeals/hotDeals.reducer";
import transactionReducer from "../views/pages/TransactionManagement/transaction.reducer";
import articleReducer from "../views/pages/stockPages/article/article.reducer";
import adminManagementReducer from "../views/pages/AdminManagement/adminManagement.reducer";
import refreshTokenReducer from "../views/pages/Login/refreshToken.reducer";
import provinceReducer from "../views/pages/stockPages/region/province.reducer";
import cityReducer from "../views/pages/stockPages/region/city.reducer";
import fileServiceReducer from "../services/fileService.reducer";
import forgotpassReducer from "../views/pages/ForgotPassword/forgotpass.reducer";
import reviewReducer from "../views/pages/RatingReviewManagament/RatingReview/ratingReview.reducer";
import settingReducer from "../views/pages/SettingPage/settingSlice";

export default configureStore({
  reducer: {
    login: loginReducer,
    article: articleReducer,
    brand: brandReducer,
    car: carReducer,
    category: categoryReducer,
    dealer: dealerReducer,
    province: provinceReducer,
    city: cityReducer,
    district: districtReducer,
    regency: regencyReducer,
    material: materialReducer,
    variant: variantReducer,
    warehouse: warehouseReducer,
    voucher: voucherReducer,
    product: productReducer,
    banner: bannerReducer,
    hotDeals: hotDealsReducer,
    bannerID: bannerReducerID,
    customer: customerReducer,
    admin: adminManagementReducer,
    transaction: transactionReducer,
    detailProduct: detailProductReducer,
    refreshToken: refreshTokenReducer,
    upload:fileServiceReducer,
    forgot:forgotpassReducer,
    review:reviewReducer,
    setting: settingReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
