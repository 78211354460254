import { lazy } from "react";

const membershipManagementRoutes = [
  {
    path: "/membership-management",
    component: lazy(() => import("../views/pages/MembershipManagement/index")),
    exact: true,
  },
  {
    path: "/membership-management/add",
    component: lazy(() => import("../views/pages/MembershipManagement/AddMembership/index")),
    exact: true,
  },
  {
    path: "/membership-management/:id",
    component: lazy(() => import("../views/pages/MembershipManagement/DetailMembership/index")),
    exact: true,
  },
  {
    path: "/membership-management/:id/edit",
    component: lazy(() => import("../views/pages/MembershipManagement/EditMembership/index")),
    exact: true,
  },
];

export default membershipManagementRoutes;
