import { createSlice } from "@reduxjs/toolkit";
import { getbannerID } from "./banner.api";

const bannerSlice = createSlice({
  name: "bannerID",
  initialState: {
    loadingID: false,
    listBannerID: [],
  },
  extraReducers: {
    [getbannerID.pending]: (state, action) => {
      state.loadingID = true;
    },
    [getbannerID.fulfilled]: (state, { payload }) => {
      state.loadingID = false;
      state.listBannerID = payload.data;
    },
    [getbannerID.rejected]: (state, action) => {
      state.loadingID = false;
    },
  },
});

export default bannerSlice.reducer;
