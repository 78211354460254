import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const postRefreshToken = createAsyncThunk("refresh-token/post", async (data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_AUTH_SERVICE_URL+"/users/refresh-token",
      data
    );
    return response.data;
  } catch (error) {
    console.log(error.response, "error response");
    return error.response;
  }
});
