import { createSlice } from "@reduxjs/toolkit";
import { getListArticle } from "./article.api";



const articleSlice = createSlice({
    name: 'article',
    initialState: {
        loading: false,
        listArticle: []
    },
    extraReducers: {
        [getListArticle.pending]: (state, action) => {
            state.loading = true
        },
        [getListArticle.fulfilled]: (state, { payload }) => {
            state.loading = false
            console.info(payload.data, "listArticle")
            state.listArticle = payload.data
        },
        [getListArticle.rejected]: (state, action) => {
            state.loading = false
        },
    }

})

export default articleSlice.reducer