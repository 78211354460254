import classNames from "classnames";
import React from "react";

import { Button } from "..";

import "./styles.scss";

const CardContent = ({ children, title, action, onClick, className }) => {
  return (
    <div className={classNames("card__content", className)}>
      <div className="content__header">
        {title !== undefined && <h2>{title}</h2>}

        {action !== undefined && (
          <Button type="button" variant="primary" textVariant="big" onClick={onClick}>
            {action}
          </Button>
        )}
      </div>

      {children}
    </div>
  );
};

const CardActions = ({ children }) => {
  return <div className="card__actions">{children}</div>;
};

const Card = ({ children, className }) => {
  return <div className={classNames("card", className)}>{children}</div>;
};

Card.Content = CardContent;
Card.Actions = CardActions;

export default Card;
