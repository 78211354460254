import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const forgotPassword = createAsyncThunk('forgotPassword/post', async (data, { rejectWithValue }) => {
    const body = {
        ...data,
        callback_url: window.location.host
    }
    try {
        const response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_AUTH_SERVICE_URL}/users/forgot-password`,
            data: body
        })
        return response.data
    } catch (error) {
        // console.log(error.response, 'error response')
        return rejectWithValue(error.response.data)
    }
})

export const setPassword = createAsyncThunk('setPassword/post', async (data, { rejectWithValue }) => {
    try {
        const response = await axios({
            method: 'put',
            url: `${process.env.REACT_APP_AUTH_SERVICE_URL}/users/set-password`,
            data: data
        })
        return response.data
    } catch (error) {
        // console.log(error.response, 'error response')
        return rejectWithValue(error.response.data)
    }
})