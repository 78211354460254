// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  width: 100%;
  --shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), var(--shadow);
  box-shadow: var(--ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--shadow);
  background-color: white;
  border-radius: 10px;
  padding: 1.25rem;
}
.card__content {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eaeaea;
}
.card__content:first-child {
  padding-top: 0;
}
.card__content:last-child {
  border-bottom: none;
  padding-top: 0;
  padding-bottom: 0;
}
.card__content .content__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}
.card__content .content__header h2 {
  color: #e9522a;
  font-size: 1.25rem;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-style: italic;
}
.card__actions {
  border-top: 1px solid #eaeaea;
  padding-top: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  column-gap: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Card/styles.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,yEAAA;EACA,qEAAA;EAAA,oHAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AACI;EACI,iBAAA;EACA,oBAAA;EACA,gCAAA;AACR;AACQ;EACI,cAAA;AACZ;AAEQ;EACI,mBAAA;EACA,cAAA;EACA,iBAAA;AAAZ;AAIY;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,sBAAA;AAFhB;AAIgB;EACI,cAAA;EACA,kBAAA;EACA,kCAAA;EACA,iBAAA;EACA,kBAAA;AAFpB;AAQI;EACI,6BAAA;EACA,iBAAA;EACA,aAAA;EACA,qCAAA;EACA,qBAAA;EAAA,gBAAA;AANR","sourcesContent":[".card {\n    width: 100%;\n    --shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);\n    box-shadow: var(--ring-offset-shadow, 0 0 #0000), var(--ring-shadow, 0 0 #0000), var(--shadow);\n    background-color: white;\n    border-radius: 10px;\n    padding: 1.25rem;\n\n    &__content {\n        padding-top: 1rem;\n        padding-bottom: 1rem;\n        border-bottom: 1px solid #eaeaea;\n\n        &:first-child {\n            padding-top: 0;\n        }\n\n        &:last-child {\n            border-bottom: none;\n            padding-top: 0;\n            padding-bottom: 0;\n        }\n\n        .content {\n            &__header {\n                display: flex;\n                align-items: center;\n                justify-content: space-between;\n                margin-bottom: 1.25rem;\n\n                h2 {\n                    color: #e9522a;\n                    font-size: 1.25rem;\n                    font-family: \"Poppins\", sans-serif;\n                    font-weight: bold;\n                    font-style: italic;\n                }\n            }\n        }\n    }\n\n    &__actions {\n        border-top: 1px solid #eaeaea;\n        padding-top: 1rem;\n        display: grid;\n        grid-template-columns: repeat(2, 1fr);\n        column-gap: 1rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
