import { lazy } from "react";

const notificationBlastRoutes = [
  {
    path: "/notification-blast",
    component: lazy(() => import("../views/pages/NotificationBlast/index")),
    exact: true,
  },
  {
    path: "/notification-blast/voucher/:id",
    component: lazy(() => import("../views/pages/NotificationBlast/VoucherDetail/index")),
    exact: true,
  },
];

export default notificationBlastRoutes;
