import React from "react";

import "./styles.scss";

const Table = ({ children, tableName, isVisible }) => {
  return <>{tableName === isVisible && <div className="table">{children}</div>}</>;
};

const TableNav = ({ children }) => {
  return <div className="table__nav">{children}</div>;
};

const TableMain = ({ children }) => {
  return <table className="table__main">{children}</table>;
};

const TableHead = ({ children }) => {
  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  );
};

const TableBody = ({ children }) => {
  return <tbody>{children}</tbody>;
};

// const TableLoading = ({ rowCount, colCount, variant }) => {
//   return (
//     <>
//       {[...Array(rowCount).keys()].map((row) => {
//         return (
//           <tr key={row}>
//             {[...Array(colCount).keys()].map((col) => {
//               return (
//                 <td key={col}>
//                   <Typography variant={variant}>
//                     <Skeleton />
//                   </Typography>
//                 </td>
//               );
//             })}
//           </tr>
//         );
//       })}
//     </>
//   );
// };

Table.Nav = TableNav;
Table.Main = TableMain;
Table.Head = TableHead;
Table.Body = TableBody;
// Table.Loading = TableLoading;

export default Table;
