import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getListBrand = createAsyncThunk(
  "getListBrand/get",
  async (params = {}, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    try {
      // Default to active status if not specified
      const brandParams = {
        ...params,
        status: params.status || "active",
      };

      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/brand`,
        params: brandParams,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteBrand = createAsyncThunk(
  "deleteBrand/delete",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    const { id } = data;
    try {
      const response = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/brand/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createBrand = createAsyncThunk(
  "createBrand/create",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/brand`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateBrand = createAsyncThunk(
  "updateBrand/update",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");

    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/brand/${data.id_param}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data.body,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailBrand = createAsyncThunk(
  "getDetailBrand/get",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    const id = data;

    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_DASHBOARD_SERVICE_URL}/dashboard/brand/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
