import { lazy } from "react";

const productRefundRoutes = [
  {
    path: "/product-refund",
    component: lazy(() => import("../views/pages/ProductRefund")),
    exact: true,
  },
  {
    path: "/product-refund/:id",
    component: lazy(() => import("../views/pages/ProductRefundDetail")),
    exact: true,
  },
];

export default productRefundRoutes;
