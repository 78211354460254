import classNames from "classnames";
import React from "react";

import "./styles.scss";

const Tab = ({ dataTabs, activeTab, onTabChange }) => {
  return (
    <div className="tab">
      {dataTabs.map((tab, i) => {
        return (
          <button
            key={i}
            onClick={() => onTabChange(tab.name)}
            type="button"
            className={classNames("tab__list", {
              active: activeTab === tab.name,
            })}
          >
            {tab.label}
          </button>
        );
      })}
    </div>
  );
};

export default Tab;
