import { createSlice } from "@reduxjs/toolkit";
import { getDetailProduct } from "./product.api";

const productSlice = createSlice({
  name: "detailProduct",
  initialState: {
    loading: false,
    DetailProduct: {},
    DetailProductSpecs: [],
    DetailProductStock: [],
  },
  extraReducers: {
    [getDetailProduct.pending]: (state, action) => {
      state.loading = true;
    },
    [getDetailProduct.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.DetailProduct = payload.data;
      // state.DetailProductSpecs = payload.data.product_specs;
      // state.DetailProductStock = payload.data.product_stocks[0];
    },
    [getDetailProduct.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default productSlice.reducer;
